Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.exampleMessage = "Graph cannot be displayed";
exports.exampleGraphLoadingError = "Graph is showing an error";
exports.LoadingGraph = "Loading...";
exports.visualGPAStatsAPIEndPoint = "bx_block_analytics9/visual_analytics/gpa_stats";
exports.visualCO2StatsAPIEndPoint = "bx_block_analytics9/co2_visual_analytics";
exports.gamificationAPIEndPoint = "bx_block_gamification/user_badges";
// Customizable Area End