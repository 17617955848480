import React from "react";
// Customizable Area Start
import {
    makeStyles,
      } from "@material-ui/core/styles";
  import { Container,Grid,Typography,Link } from "@material-ui/core";
import { circleBottomLeft,onboardingOneIcon,popupIcon,redArrowIcon,tableIcon,circleTopLeft,circleTopRight,weeklyIcon,windEverest} from "./assets";
const useStyles = makeStyles({
  
    })
import "../css/OnboardingOneWeb.css"
const navigateToPages=(navItem:string)=>{
    if(navItem.includes("termsandconditions")){
     localStorage.setItem("windUserNotificationLink","TermsConditions");
    }
    else if(navItem.includes("privacypolicy")){
      localStorage.setItem("windUserNotificationLink","PrivacyPolicy");
    }
    else if(navItem.includes("contactus")){
        localStorage.setItem("windUserNotificationLink","ContactUs");
    }
  }

  const styles = {
    containerStyle:{
        overflow :"hidden"
    }
  }
const OnboardingOne = ()=>{
    return (
        <Container className="OnboardingOneContainer">
            <Grid container className="topRowGrid">
                <Grid item md={3} className="logoGridOne">
                    <img src={circleTopLeft} className="circleTopLeftImgOne"/>
                    <img src={windEverest} className="windEverestLogoOne"/>
                </Grid>
                <Grid item md={6} className="infoGridOne">
                    <Grid container>
                       <Grid item md={1} className="infoGridIconOne">
                            <img src={redArrowIcon} className="arrowIconsOne"/>
                        </Grid>
                        <Grid item md={11}>
                            <Typography className="arrowOneInfo">Each day of the week.</Typography>
                        </Grid>
                        <Grid item md={1} className="infoGridIconTwo">
                            <img src={redArrowIcon} className="arrowIconsOne"/>
                        </Grid>
                        <Grid item md={11}>
                            <Typography className="arrowTwoInfo">Seasonally adjusted performance for winds expected.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} className="rightIconGrid">
                    <img src={circleTopRight} className="rightCircleIconOne" />
                </Grid>
            </Grid>
            <Grid container style={styles.containerStyle as React.CSSProperties} >
                <Grid item md={3} xs={3}>
                    <img src={circleBottomLeft} className="circleBottomLeftOne"></img> 
                </Grid>
                <Grid item md={6} xs={6}>
                    <img src={onboardingOneIcon} className="laptopImageOne"></img>
                </Grid>
                <Grid item md={3} xs={3} className="popupTableGrid">
                    <img src={popupIcon} className="popupIconWeeklyData"></img>
                    <Grid container className="headingsWeekly">
                        <Grid item md={4}>
                            <Typography className="wLHeading">W/L</Typography>
                        </Grid>
                        <Grid item md={4}>
                            <Typography className="DOWHeading">DOW</Typography>
                        </Grid>
                        <Grid item md={4}>
                            <Typography className="gradeHeading">Grade</Typography>
                        </Grid>
                    </Grid>
                    <img src={weeklyIcon} className="weeklyDataPopupIcon"></img>
                    <img src={tableIcon} className="weeklyTableIconOne"></img>
                    <Grid container className="tableWeekly">
                                                <Grid item md={6} className="tableRows">
                            <Typography className="dayRow">Thursday</Typography>
                            <Typography className="gradeRow">F</Typography>
                        </Grid>
                        <Grid item md={6} className="tableRows">
                            <Typography className="dayRow">Friday</Typography>
                            <Typography className="gradeRow1">D</Typography>
                        </Grid>
                        <Grid item md={6} className="tableRows">
                            <Typography className="dayRow">Saturday</Typography>
                            <Typography className="gradeRow2">B</Typography>
                        </Grid>
                        <Grid item md={6} className="tableRows">
                            <Typography className="dayRow">Sunday</Typography>
                            <Typography className="gradeRow3">B</Typography>
                        </Grid>
                        <Grid item md={6} className="tableRows">
                            <Typography className="dayRow">Monday</Typography>
                            <Typography className="gradeRow4">C</Typography>
                        </Grid>
                        <Grid item md={6} className="tableRows">
                            <Typography className="dayRow">Tuesday</Typography>
                            <Typography className="gradeRow5">F</Typography>
                        </Grid>
                        <Grid item md={6} className="tableRows">
                            <Typography className="dayRow">Wednesday</Typography>
                            <Typography className="gradeRow6">F</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={6} className="infoThirdLowerGrid">
                        <Grid item md={1} className="infoGridIcon">
                            <img src={redArrowIcon} className="arrowIconsOne"/>
                        </Grid>
                        <Grid item md={11}>
                            <Typography className="arrowThreeInfo">Nightly fine-grained wind intensities: redder = worse; bluer=better.</Typography>
                        </Grid>
            </Grid>
            <Grid container className="footerOnBoardingOddOne">    
            <Grid item md={4} className="typographyTACGrid">
                <Link variant="body1" className="typographyTAC" href="/WebNavigationMenu" onClick={()=>navigateToPages("termsandconditions")}>Terms and Conditions</Link>
            </Grid>
            <Grid item md={4} className="typographyPolicyGrid">
                <Link variant="body1" className="typographyPolicy" href="/WebNavigationMenu" onClick={()=>navigateToPages("privacypolicy")}>Policy</Link>
            </Grid>
            <Grid item md={4} className="typographyContactGrid">
                <Link variant="body1" className="typographyContact" href="/WebNavigationMenu" onClick={()=>navigateToPages("contactus")}>Contact us</Link>
            </Grid>
        </Grid>            
        </Container>
    )
}
// Customizable Area End
export default OnboardingOne;