Object.defineProperty(exports, "__esModule", {
  value: true
});
const urlConfig = require("../../../framework/src/config")

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.apiMethodTypeCars = "GET";


exports.errorEmailNotValid = "cars data error.";
exports.urlGetCars =
  "bx_block_categories/cars/dropdown_details";
exports.carModelEndPoint = "bx_block_categories/cars/dropdown_details";
exports.usernameExistEndPoint = "account_block/accounts/check_user_name_validation";
exports.cityApiEndPoint = "bx_block_states_cities/state_cities/cities";
exports.zipcodeEndPoint = "bx_block_states_cities/state_cities/zipcode_validation";
exports.stateEndPoint = "bx_block_states_cities/state_cities/states";
exports.emailExistEndPoint = "account_block/accounts/check_email_validation";
exports.validationOTPEndPoint = "bx_block_forgot_password/otp_confirmations";
exports.termsAndConditionEndPoint = "bx_block_contact_us/privacy_policies/terms_and_condition_list_page";

exports.carsApiContentType = "application/json";

exports.carsApiMethodType = "GET";
exports.baseUrl = urlConfig.baseURL;
exports.mandatoryEmail="EmailId is mandatory";
exports.allreadyExist="Email is already exists, please choose a different email";
exports.invalidEmail ="Email is not valid, please enter correct email pattren example@domain.com";
exports.emailValidPattern="/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/";
exports.usernamePattern=/^[a-zA-Z ]*$/;
exports.invalidUsername="Username is not valid use only lowercase, uppercase and digits";
exports.existUsername="Username already exists, please choose a different username";
exports.usernameMandatory="Username is mandatory";
exports.confirmPasswordMatchError="Confirm password doesn't match with password.";
exports.confirmPasswordMandatory="Confirm password is mandatory.";
exports.zipCodeMandatory="Zipcode is mandatory";
exports.invalidZipcode="Zipcode is not valid";
exports.carNamePattern=/^[a-zA-Z][a-zA-Z0-9!-]+$/;
exports.invalidCarname="Letters, numbers, dashes and exclamation points only";
exports.reuiredcarName="Car name is mandatory";
exports.kwValidatorPattern=/^(?:\d{1,2})?\.?(?:\d{1,2})$/;
exports.invalidKw= "kW value should be in a range of (1-999), in numeric only";
exports.kwerror="Only numeric values should be entered";
exports.pwdValidator=/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?!.*\s).*$/;
exports.fieldRequireds="This is required field";
exports.fieldRequiredMsgs="This field should contains at least 1 uppercase, 1 lowercase, 1 special character and length should be at least 8 characters";
exports.createAccountMethodType = "POST";
// Customizable Area End
