import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { saveAs } from "file-saver";
import axios,{ AxiosRequestConfig }  from "axios";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;
  updateData:boolean,
  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  username: string;
  password: string;
  reTypePassword: string;
  streetAddress: string;
  streetAddressError:boolean;
  stateProvince: string;
  city: string;
  zipcode: string;
  carName: string;
  carNameError:boolean;
  carNameErrorMsg:string;
  electricCarModel: string;
  electricCarMake: string;
  electricCarYear: string;
  electricCarkWh:string;
  carBatterySize:string;
  kwError:boolean;
  kwErrorMsg:string;
  batterySizeError: boolean;
  batterySizeErrorMsg: string;
  overNightCharger: string;
  questionList: any;
  successMsg: boolean,
  carModels: any;
  carModel: any;
  carYear: any;
  carBrand: any;
  batterySize:any;
  usernameError: boolean;
  usernameErrorMsg: string;
  emailError: boolean;
  emailErrorMsg: string;
  comfortLevel: string;
  comfortLevelError:boolean;
  comfortLevelErrorMsg:string;
  drivingExperience: string;
  lowCarbon: string;
  comuteDistance: string;
  precondition: string;
  stateProvinceList: any;
  location: any;
  zipCodeError: boolean;
  zipErrorMsg:string;
  cityList: any;
  enableDeleteMessage:boolean;
  enableFeedbackModal:boolean;
  feedbackOptions:string[];
  selectedFeedback:string;
  downloadProfileData:boolean;
  otherFeedback:string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  questionApiId: string = "";
  updateUserProfileApiId: string = "";
  checkUsernameExistId: string="";
  checkEmailExistId: string="";
  carsApiCallId: string="";
  stateCallId: any="";
  zipcodeapiCallId: string="";
  cityCallApiId: string="";
  feedbackAPIId:string="";
  sendFeedbackAPIId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      username: "",
      password: "",
      reTypePassword: "",
      streetAddress: "",
      streetAddressError:false,
      stateProvince: "",
      city: "",
      zipcode: "",
      carName: "",
      carNameError:false,
      carNameErrorMsg:"",
      electricCarModel: "",
      electricCarMake: "",
      electricCarYear: "",
      electricCarkWh:"",
      carBatterySize:"",
      kwError:false,
      kwErrorMsg:"",
      batterySizeError: false,
      batterySizeErrorMsg:"",
      overNightCharger: "",
      questionList: [],
      successMsg: false,
      updateData:false,
      carModels: [],
      carBrand: [],
      carModel: [],
      carYear: [],
      batterySize:[],
      usernameError: false,
      emailError: false,
      emailErrorMsg: '',
      comfortLevel:"",
      comfortLevelError: false,
      comfortLevelErrorMsg:"",
      drivingExperience: "",
      lowCarbon: "",
      comuteDistance: "",
      precondition: "None",
      stateProvinceList: [],
      usernameErrorMsg: "",
      location: {
        stateCode: "al",
        countryCode: "us"
      },
      zipCodeError: false,
      zipErrorMsg:"",
      cityList: [],
      enableDeleteMessage:false,
      enableFeedbackModal:false,
      feedbackOptions:[],
      selectedFeedback:"",
      downloadProfileData:false,
      otherFeedback:"",
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      this.currentCountryCodeMethod(selectedCode);
      
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        console.log("First If")
        switch(apiRequestCallId){
          case this.validationApiCallId:
            console.log("Validation API", apiRequestCallId)
            this.validationApiCallMethod(responseJson);  
          break;
          case this.userProfileGetApiCallId:
            console.log(" API", apiRequestCallId)
            this.userProfileGetApiCallMethod(responseJson,errorReponse);
          break;
          case this.apiChangePhoneValidation:
            console.log(" API", apiRequestCallId)
            this.apiChangePhoneValidationMethod(responseJson,errorReponse);
          break;
          case this.questionApiId:
            this.questionApiMethod(responseJson,errorReponse);
          break;
          case this.carsApiCallId:
            this.carsApiCallMethod(responseJson,errorReponse);
          break;
          case this.checkUsernameExistId:
            this.checkUsernameExistMethod(responseJson);
          break;
          case this.stateCallId:
            this.setState({
              stateProvinceList: responseJson.states
            });
          break;
          case this.cityCallApiId:
            this.cityCallApiMethod(responseJson,errorReponse);
          break;
          case this.zipcodeapiCallId:
            this.zipcodeApiMethod(responseJson,errorReponse); 
          break;
          case this.feedbackAPIId:
            this.feedbackAPIMethod(responseJson,errorReponse);
          break;
          case this.checkEmailExistId:
            this.checkEmailExistMethod(responseJson);  
          break;
          case this.updateUserProfileApiId:
            this.updateUserProfileApiMethod(responseJson,errorReponse);
          break;
          case this.apiCallMessageUpdateProfileRequestId:
            this.apiCallMessageUpdateProfileRequestMethod(responseJson,errorReponse);
          break;
          case this.sendFeedbackAPIId:
            console.log("Account is deleted",responseJson)
            localStorage.removeItem("windToken");
            localStorage.removeItem("windToken");
            this.props.navigation.navigate("EmailAccountLoginBlock",{replace:true});  
          break;

        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;

        this.getUserProfile();
      }
    }

    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  async componentDidMount() {
    super.componentDidMount();
    await this.questionApiCall();
    await this.updateFields();
    await this.updateCarModels();
    const token = localStorage.getItem("windToken");
    console.log(token, "this is a token", this.props);
    if (token === null || token === undefined || token === '') {
      console.log("history", this.props.history);
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
    this.stateCallApi("us");
  }
  currentCountryCodeMethod=(selectedCode:string)=>{
    if (selectedCode !== undefined) {
      this.setState({
        currentCountryCode:selectedCode.indexOf("+") > 0 ? selectedCode.split("+")[1] : selectedCode
      });
    }
  }

  validationApiCallMethod=(responseJsonData:any)=>{
    this.arrayholder = responseJsonData.data;
    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData) {
        if (regexData.password_validation_regexp) {
          this.passwordReg = new RegExp(
            regexData.password_validation_regexp
          );
        }
        if (regexData.email_validation_regexp) {
          this.emailReg = new RegExp(regexData.email_validation_regexp);
        }
        if (regexData.email_validation_regexp) {
          this.setState({
            passwordHelperText: regexData.password_validation_rules
          });
        }
      }
    }
  }
  setProfileDataAttributes=(userAttributes:any)=>{
        let email = userAttributes.email;
        let firstName = userAttributes.first_name;
        let lastName = userAttributes.last_name;
        let currentCountryCode = userAttributes.country_code;
        let phoneNumber = userAttributes.phone_number ? userAttributes.phone_number : "";
        this.setState({
          email: email,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber
        });
        if (userAttributes.country_code) {
          this.setState({ currentCountryCode: currentCountryCode });
        }
  }
  userProfileGetApiCallMethod=(responseJsonData:any,errorReponse:any)=>{
    if ( responseJsonData && !responseJsonData.errors && responseJsonData.data && responseJsonData.data.attributes) {
      //On User Profile Success
      this.userAttr = responseJsonData.data.attributes;
      console.log("Profile Attributes", this.userAttr)
      if (this.userAttr !== null && this.userAttr !== undefined) {
        this.setProfileDataAttributes(this.userAttr);
        
        //@ts-ignore
        this.txtInputFirstNameProps.value = firstName;

        //@ts-ignore
        this.txtInputLastNameProps.value = lastName;

        //@ts-ignore
        this.txtInputEmailProps.value = email;

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = phoneNumber;

        this.registrationAndLoginType = this.userAttr.type;

        switch(this.registrationAndLoginType){
          case configJSON.ACCOUNT_TYPE_EMAIL:
            this.setState({
              edtEmailEnabled: false,
              llChangePwdDummyShowContainerVisible: true
            });
            this.txtInputEmailProps.editable = false;
          
          break;
          case configJSON.ACCOUNT_TYPE_SOCIAL:
            console.log("It's inside social block")
          this.setState({
            edtEmailEnabled: false,
            llChangePwdDummyShowContainerVisible: false,
            llDoChangePwdContainerVisible: false
          });
          this.txtInputEmailProps.editable = false;
        
          break;
          case configJSON.ACCOUNT_TYPE_PHONE:
            this.setState({
              llChangePwdDummyShowContainerVisible: true,
              edtMobileNoEnabled: false,
              countryCodeEnabled: false
            });
            this.txtInputPhoneNumberProps.editable = false;  
          break;
        }
        
      }
    } else {
      //Check Error Response
      if (responseJsonData.errors && responseJsonData.errors.length > 0 && responseJsonData.errors[0].token) {
        this.showAlert("Session Expired", "Please Log in again.");
      } else {
        this.parseApiErrorResponse(responseJsonData);
      }
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
  apiChangePhoneValidationMethod=(responseJsonData:any,errorReponse:any)=>{
    if (responseJsonData != null && responseJsonData.errors === undefined) {
      //On Change Phone Validation Success
      this.validateAndUpdateProfile();
    } else {
      this.parseApiErrorResponse(responseJsonData);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }
  questionApiMethod=(responseJsonData:any,errorReponse:any)=>{
    if (responseJsonData != null && responseJsonData.errors === undefined) {
            
      //On Change Phone Validation Success
      this.setState({
        questionList: responseJsonData.data.map((item: any, index: number) => {
          return {
            id: item.id,
            attributes: {
              answer: [],
              content: item.attributes.content,
              answer_type: item.attributes.answer_type,
              options: item.attributes.options
            }
          }
        })
      })
      this.updateFields();

    } else if (responseJsonData && responseJsonData.errors) {
      if (responseJsonData.errors[0]?.token === "Token has Expired") {
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }
    }
    else {
      this.parseApiErrorResponse(responseJsonData);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }
  carsApiCallMethod=(responseJsonData:any,errorReponse:any)=>{
    if (responseJsonData != null && responseJsonData.errors === undefined) {
      //On Change Phone Validation Success
      const value = responseJsonData.values;
      const make = Object.keys(value[0]);
      const makeIndex = make.indexOf(this.state.electricCarMake);
      const model = Object.keys(value[0][make[makeIndex]][0]);
      const modelIndex = model.indexOf(this.state.electricCarModel);
      const year = Object.keys(value[0][make[makeIndex]][0][model[modelIndex]]);
      const yearIndex = year.indexOf(this.state.electricCarYear)
      const batterySize = value[0][make[makeIndex]][0][model[modelIndex]][year[yearIndex]];
      console.log("cars featuer ============> ", make, model, year,batterySize);
      this.setState({
        carModels: responseJsonData.values[0],
        carBrand: make,
        carModel: model,
        carYear: year,
        batterySize: batterySize,
      });

    }
    else if (responseJsonData && responseJsonData.errors) {
      if (responseJsonData.errors[0]?.token === "Token has Expired") {
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }
    }
    else {
      this.parseApiErrorResponse(responseJsonData);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }
  checkUsernameExistMethod=(responseJsonData:any)=>{
    this.setState({
      usernameError: (responseJsonData.errors) ? true : false,
    });
    console.log(responseJsonData);
  }
  cityCallApiMethod=(responseJsonData:any,errorReponse:any)=>{
    if (responseJsonData !== undefined && responseJsonData.errors === undefined) {
      console.log("city =====================> ", responseJsonData);
      this.setState({
        cityList: responseJsonData.Cities
      });
    }
    else if (responseJsonData && responseJsonData.errors) {
      if (responseJsonData.errors[0]?.token === "Token has Expired") {
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }
    }
    else {
      console.log("problem to fetch city, try latter, lin 338");
    }
  }
  zipcodeApiMethod=(responseJsonData:any,errorReponse:any)=>{
    if (responseJsonData !== undefined && responseJsonData.errors === undefined) {
      console.log("city =====================> ", responseJsonData);
      this.setState({
        zipCodeError: false
      });
    } else {
      console.log("problem to fetch zipcode validation, try latter, lin 338");
      this.setState({
        zipCodeError: true
      });
    }
  }
  feedbackAPIMethod=(responseJsonData:any,errorReponse:any)=>{
    if (responseJsonData !== undefined && responseJsonData.errors === undefined) {
      console.log("city =====================> ", responseJsonData);
      this.setState({
        feedbackOptions: responseJsonData.feedback_list
      });
      console.log(this.state.feedbackOptions);
    } 
  }
  checkEmailExistMethod=(responseJsonData:any)=>{
    this.setState({
      emailError: (responseJsonData.errors) ? true : false,
    });
  }
  updateUserProfileApiMethod=(responseJsonData:any,errorReponse:any)=>{
    if (responseJsonData != null && responseJsonData.errors === undefined) {
      //On Change Phone Validation Success
      this.setState({
        successMsg: true,
      });
      localStorage.setItem("windUser", JSON.stringify(responseJsonData));
      this.updateFields();
      const timer = setTimeout(() => {
        this.setState({
          successMsg: false
        });
        clearTimeout(timer);
      }, 2000);
      console.log("update prolef call ============>", responseJsonData);
    } else {
      this.parseApiErrorResponse(responseJsonData);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
  apiCallMessageUpdateProfileRequestMethod=(responseJsonData:any,errorReponse:any)=>{
    if (responseJsonData != null && responseJsonData.errors === undefined) {
      //On Change Phone Validation Success

      this.showAlert("Success", "Profile updated successfully.");
      this.enableDisableEditPassword(false);
      this.getUserProfile();
    } else {
      this.parseApiErrorResponse(responseJsonData);
    }
  }
  
  updateFields = () => {
    
    const data = JSON.parse(localStorage.getItem("windUser")!);

    this.state.questionList.map((item: any, index: any) => {
      data?.data.attributes.answer_attributes?.map((dataItem: any) => {
        if (dataItem.question_id === item.id) {
          const list = [...this.state.questionList]; 
          list[index].attributes.answer = dataItem.content
          this.setState({
            questionList: list
          });
        }
      })

    })

    this.setState({
      username: data?.data?.attributes?.user_name ? data?.data?.attributes?.user_name : "",
      email: data?.data?.attributes?.email,
      city: data?.data?.attributes?.address_attributes?.city ? data?.data?.attributes?.address_attributes?.city : "" ,
      streetAddress: data?.data?.attributes?.address_attributes?.address,
      zipcode: data?.data?.attributes?.address_attributes?.zipcode,
      carName: data?.data?.attributes?.car_attributes?.car_name,
      electricCarkWh: data?.data?.attributes?.car_attributes?.maximum_km,
      stateProvince: data?.data?.attributes?.address_attributes?.state_or_province,
      overNightCharger: data?.data?.attributes?.question_choice_type,
      comfortLevel: data?.data?.attributes?.comfort_level,
      lowCarbon: data?.data?.attributes?.other,
      drivingExperience: data?.data?.attributes?.electric_vehicle_break,
      comuteDistance: data?.data?.attributes?.commute_distance,
      precondition:data?.data?.attributes?.preconditioning_type,
      electricCarMake:data?.data?.attributes?.car_attributes?.electric_car_make,
      electricCarYear:data?.data?.attributes?.car_attributes?.electric_car_year,
      electricCarModel:data?.data?.attributes?.car_attributes?.electric_car_model,
      carBatterySize: data?.data?.attributes?.battery_size
      // questionList:data?.data.attributes.answer_attributes.content

    });

  }
  
  
  stateCallApi = (countryCode: string) => {
    const header = {
      "Content-Type": configJSON.carsApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.stateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/bx_block_states_cities/state_cities/states?country_code=${countryCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  questionApiCall = () => {
    const token = localStorage.getItem("windToken");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.questionApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_categories/questions/?question_type=Profile'
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  cityCallapi = (stateCode: string, countryCode: string) => {
    this.setState({
      location: {
        stateCode: stateCode,
        countryCode: countryCode
      }
    });
    const header = {
      "Content-Type": configJSON.carsApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cityCallApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/bx_block_states_cities/state_cities/cities?alpha_code=${stateCode}&country_code=${countryCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  zipcodeApiCall = (code: string) => {

    const header = {
      "Content-Type": configJSON.carsApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.zipcodeapiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/bx_block_states_cities/state_cities/zipcode_validation?zipcode=${code}&city=${this.state.city}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  deleteAccount=()=>{
    this.setState({
      enableDeleteMessage:true
    })
  }
  sendFeedback=()=>{
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

      this.sendFeedbackAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/account_block/delete_accounts/`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
       JSON.stringify({"account":{"feedback":this.state.selectedFeedback || this.state.otherFeedback}})
   );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  }

  downloadAPICallMethod=()=>{
    const token = localStorage.getItem("windToken");
    const userId = JSON.parse(localStorage.getItem("windUser")!).data.id;
    const requestConfig:Partial<AxiosRequestConfig> = {
      url:  `${configJSON.baseUrl as string}/account_block/accounts/${userId as string}/download_data`,
      method:'get',
      responseType:'blob',
      headers:{
        'Content-Type':'application/xlsx',
        token: token as string,
      },
    }; 
    axios(requestConfig as AxiosRequestConfig).then((response)=>{
      const file = new Blob([response.data],{type:'application/octet-stream'});
      saveAs(file,'UserProfileData.xlsx')
      console.log("File downloaded successfully");
    }).catch((error)=>{
      console.log("Error in download",error);
    })
  }
  getFeedbackOptions=()=>{
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.carsApiContentType,
      token: token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.feedbackAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/account_block/delete_accounts/feedbacks`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  updateUserProfileApiCall = () => {

    const userId = JSON.parse(localStorage.getItem("windUser")!).data.id;
    let token = localStorage.getItem("windToken");

    const header = {
      // "Content-Type": 'multipart/form-data',
      token: token
    };

    //   var formData = new FormData();
    // formData.append('key1', 'value1');
    // formData.append('key2', 'value2');

    // for (var pair of formData.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]); 
    // }
    var formdata = new FormData();
    // formdata.append("rahul", "akash");
    // for (var pair of formdata.entries()) {
    //   console.log(pair[0]+ ', ' + pair[1]); 
    // }
    // formdata.append("account[name]", "mandeepsss");
    formdata.append("account[email]", this.state.email);
    // formdata.append("account[password]", "rahulkumar");
    formdata.append("account[user_name]", this.state.username);
    formdata.append("account[question_choice_type]", this.state.overNightCharger);
    formdata.append("account[address_attributes][address]", this.state.streetAddress);
    formdata.append("account[address_attributes][city]", this.state.city);
    formdata.append("account[address_attributes][state_or_province]", this.state.stateProvince);
    formdata.append("account[address_attributes][zipcode]", this.state.zipcode);
    formdata.append("account[car_attributes][car_name]", this.state.carName);
    formdata.append("account[car_attributes][electric_car_model]", this.state.electricCarModel);
    // formdata.append("account[car_attributes][electric_car_model]", this.state.electric_car_model);
    formdata.append("account[car_attributes][maximum_km]", this.state.electricCarkWh);
    formdata.append("account[car_attributes][electric_car_year]", this.state.electricCarYear);
    formdata.append("account[car_attributes][electric_car_make]", this.state.electricCarMake);
    formdata.append("account[battery_size]",this.state.carBatterySize);
    // formdata.append("account[car_attributes][electric_car_year]", this.state.electric_car_year);
    // formdata.append("account[car_attributes][electric_car_make]", this.state.electric_car_make);
    formdata.append("account[preconditioning_type]", this.state.precondition || "None");
    formdata.append("account[commute_distance]", this.state.comuteDistance);
    formdata.append("account[comfort_level]", this.state.comfortLevel);
    // formdata.append("account[comfort_level]", this.state.comfortLevel);
    formdata.append("account[electric_vehicle_break]", this.state.drivingExperience);
    formdata.append("account[other]", this.state.lowCarbon);
    console.log("Questions from backend",this.state.questionList)
    if(this.state.questionList.length){
      this.state.questionList.map((item: any, index: number) => {
        let answer :any = item.attributes.answer;
        console.log(typeof answer)
        console.log(answer)
        answer= (typeof answer == "string") ? JSON.parse(answer) : answer;
      
        formdata.append("account[answer_attributes][][question_id]", item.id);
        formdata.append("account[answer_attributes][][answer]", JSON.stringify(answer));
        formdata.append("account[answer_attributes][][answer_type]", JSON.stringify(item.attributes.answer_type));
        console.log(item.attributes.options, item.attributes.answer, "----------> check");
      });
        
    }
    else{
      formdata.append("account[answer_attributes][][question_id]", "");
      formdata.append("account[answer_attributes][][answer]", "[]");
      formdata.append("account[answer_attributes][][answer_type]", "[]");

    }
    console.log("This is been sent.",formdata);
    console.log("This is precondition right now", this.state.precondition);
    const httpBody = {
      account: formdata,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserProfileApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/account_block/accounts/${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      (formdata)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateCarModels() {
    const header = {
      "Content-Type": configJSON.carsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.carsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/bx_block_categories/cars/dropdown_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCarMake = () => {
    return Object.keys(this.state.carModels);
  }
  getCarModel = (makeof: any) => {
    return Object.keys(this.state.carModels[makeof][0]);
  }
  getCarYear = (makeof: any, model: any) => {
    return Object.keys(this.state.carModels[makeof][0][model]);
  }
  getCarbatterySize = (makeof:any, model:any, year:any)=>{
    return this.state.carModels[makeof][0][model][year];
  }

  checkUsernameExist(name: string) {

    const header = {
      "Content-Type": configJSON.carsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkUsernameExistId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/account_block/accounts/check_user_name_validation?user_name=${name}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  checkEmailExist(mail: string) {

    const header = {
      "Content-Type": configJSON.carsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkEmailExistId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/account_block/accounts/check_email_validation?email=${mail}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps = {
    value: "",
    editable: true,
    onChangeText: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry
      });
      this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
      this.imgNewPasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry
      });
      this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
      this.imgReTypePasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }
  // Customizable Area End

}
