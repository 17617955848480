import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { boolean } from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  streetAddress: string;
  stateProvince: string;
  city: string;
  zipCode: string;
  carName: string;
  electricCarMake: string;
  electricCarModel: string;
  electricCarYear: string;
  electricCarkWh:number;
  carBatterySize:string;
  modalState: boolean;
  error: any;
  label: any;
  carModels: any;
  token: any;
  termandcondition: boolean;
  overNightChargeType: string;
  carBrand: any;
  carModel: any;
  carYear: any;
  batterySize:any;
  usernameErrorMsg:string;
  usernameError: boolean;
  emailErrorMsg: string;
  emailError: boolean;
  otp: any;
  stateProvinceList: any;
  location: any;
  cityList: any;
  zipCodeError: boolean;
  passwordErrorMsg:string;
  passwordError: boolean;
  streeaddressError: boolean;
  stateError: boolean;
  cityError: boolean;
  zipError: boolean;
  zipErrorMsg: string;
  carnameError: boolean;
  carnameErrorMsg:string;
  carmakeError: boolean;
  carmodelError: boolean;
  caryearError: boolean;
  batterySizeError: boolean;
  batterySizeErrorMsg: string;
  kwError:boolean;
  kwErrorMsg:string;
  questionError: boolean;
  questionErrorMsg:boolean;
  termconditionError: boolean;
  repasswordError: boolean;
  repasswordErrorMsg: string;
  checkTC:boolean;
  signUpSuccess:boolean;
  termsConditions:any;
  carYearSelectError:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  carsApiCallId: string;
  checkEmailExistId: string;
  checkUsernameExistId: string;
  validateOTPId: string;
  createNewPasswordId: string;
  stateCallId: string;
  cityCallApi: any;
  cityCallApiId: any;
  zipcodeapiCallId: string;
  termsAndConditionsId:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      streetAddress: "",
      stateProvince: "",
      city: "",
      zipCode: "",
      carName: "",
      electricCarMake: "",
      electricCarModel: "",
      electricCarYear: "",
      electricCarkWh:0,
      carBatterySize:"",
      username: "",
      modalState: false,
      termandcondition: false,
      carYearSelectError:false,
      error: {
        username: false,
        password: false,
        repassword: false,
        countrycode: false,
        phone: false,
        streeaddress: false,
        stateprovince: false,
        city: false,
        zipcode: false,
        carname: false,
        electriccarmake: false,
        electriccarmodel: false,
        electriccaryear: "",
        maxkw: false,
        termandcondition: false,
      },
      label: [
        {
          index: 1,
          lable: "Alabama(AL)",
          isSelected: false,
        },
        {
          index: 2,
          lable: "Alaska(AK)",
          isSelected: false,
        },
        {
          index: 3,
          lable: "Arizona(AZ)",
          isSelected: false,
        },
        {
          index: 4,
          lable: "Arkansas(AR)",
          isSelected: false,
        },
        {
          index: 5,
          lable: "California(CA)",
          isSelected: false,
        },
        {
          index: 6,
          lable: "Maine(ME)",
          isSelected: false,
        },
        {
          index: 7,
          lable: "Maryland(MD)",
          isSelected: false,
        },
        {
          index: 8,
          lable: "Massachusetts(MA)",
          isSelected: false,
        },
        {
          index: 9,
          lable: "Michigan(MI)",
          isSelected: false,
        },
        {
          index: 10,
          lable: "Minnesota(MN)",
          isSelected: false,
        },
      ],
      carModels: [],
      token: "",
      overNightChargeType: "",
      carBrand: [],
      carModel: [],
      carYear: [],
      batterySize:[],
      usernameErrorMsg:"",
      usernameError: false,
      emailErrorMsg: "",
      emailError: false,
      otp: [new Array(4).fill("")],
      stateProvinceList: [],
      location: {
        stateName: "",
        stateCode: "al",
        countryCode: "us"
      },
      cityList: [],
      zipCodeError: false,
      zipErrorMsg:"",
      passwordError: false,
      passwordErrorMsg:"",
      streeaddressError:false,
      stateError:false,
      cityError: false,
      zipError: false,
      carnameError: false,
      carnameErrorMsg:"",
      carmakeError: false,
      carmodelError: false,
      caryearError: false,
      batterySizeError: false,
      batterySizeErrorMsg:"",
      kwError:false,
      kwErrorMsg:"",
      questionError:false,
      questionErrorMsg:false,
      termconditionError: false,
      repasswordError: false,
      repasswordErrorMsg:"",
      checkTC:false,
      signUpSuccess:false,
      termsConditions:[]
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.carsApiCallId="";
    this.checkEmailExistId="";
    this.checkUsernameExistId="";
    this.validateOTPId="";
    this.createNewPasswordId="";
    this.stateCallId="";
    this.zipcodeapiCallId="";
    this.termsAndConditionsId="";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch(apiRequestCallId){
          case this.validationApiCallId:
            this.validationApiCallMethod(responseJson);
          break;
          case this.carsApiCallId:
            this.carsApiCallMethod(responseJson);
          break;
          case this.validateOTPId:
            this.validateOTPMethod(responseJson);
          break;
          case this.checkUsernameExistId:
            this.checkUsernameExistMethod(responseJson);
          break;
          case this.checkEmailExistId:
            this.checkEmailExistMethod(responseJson);
          break;
          case this.cityCallApiId:
            this.cityCallApiMethod(responseJson);
          break;
          case this.termsAndConditionsId:
            this.termsAndConditionsMethod(responseJson);
          break;
          case this.zipcodeapiCallId:
            this.zipcodeapiCallMethod(responseJson);
          break;
          case this.stateCallId:
            this.setState({
              stateProvinceList: responseJson.states,
              location:{
                countryCode: responseJson.country_code
              }
            });
          break;
          case this.createAccountApiCallId:
            console.log("create new account api calling start .........1");
            this.createAccountApiCallMethod(responseJson,errorReponse);
          break;
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      this.otpAuthTknMethod(otpAuthTkn,message.id);
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
        this.countryCodeMessageMethod(selectedCode);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  validationApiCallMethod=(responseJsonData:any)=>{
    this.arrayholder = responseJsonData.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
  }
  carsApiCallMethod=(responseJsonData:any)=>{
    if (!responseJsonData.errors) {
      const value = responseJsonData.values;
      const make  = Object.keys(value[0]);
      const model = Object.keys(value[0][make[0]][0]);
      const year = Object.keys(value[0][make[0]][0][model[0]]);
      const batterySize = value[0][make[0]][0][model[0]][year[0]];
      this.setState({
        carModels: responseJsonData.values[0],
        carBrand : make,
        carModel: model,
        carYear : year,
        batterySize: batterySize,
      });
      console.log("carapicalled ====>", responseJsonData);
    }
  }
  validateOTPMethod=(responseJsonData:any)=>{
    if (!responseJsonData.errors) {
      console.log("succedss ====>", responseJsonData);
      if(responseJsonData.messages !== undefined ){
        localStorage.setItem("vToken", responseJsonData.messages[0].token);
        this.props.navigation.navigate("CreateNewPassword");
      }
    }
  }
  checkUsernameExistMethod= (responseJsonData:any)=>{
       
    this.setState({
      usernameError: (responseJsonData.errors) ? true : false,
      usernameErrorMsg:responseJsonData?.errors?.[0]?responseJsonData?.errors?.[0]:""
    });
    console.log(this.state.username, responseJsonData,"testsestestest");
  }
  checkEmailExistMethod=(responseJsonData:any)=>{
    console.log("Response Data",responseJsonData);
    this.setState({
      emailError: (responseJsonData.errors) ? true : false,
    });
  }
  cityCallApiMethod=(responseJsonData:any)=>{
    if(responseJsonData !== undefined && responseJsonData.errors === undefined){
      console.log("city =====================> ", responseJsonData);
      
      this.setState({
        cityList: responseJsonData.Cities
      });
    }else{
      console.log("problem to fetch city, try latter, lin 338");
    }
  }
  termsAndConditionsMethod=(responseJsonData:any)=>{
    if(responseJsonData !== undefined && responseJsonData.errors === undefined){
      console.log("city =====================> ", responseJsonData);
      this.setState({
        termsConditions: responseJsonData?.data[0]?.attributes?.description
      });
    }
  }
  zipcodeapiCallMethod= (responseJsonData:any)=>{
    if(responseJsonData.errors){
      this.setState({
        zipCodeError:true
      })
    } else if(responseJsonData.message){
      this.setState({
        zipError:false,
        zipErrorMsg:""
      })
    }
  }
  createAccountApiCallMethod=(responseJsonData:any,errorResponseData:any)=>{
    if (responseJsonData !== undefined && responseJsonData.data) {

      console.log("create new account api calling start .........2");
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );

      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

      localStorage.setItem("windUser", JSON.stringify(responseJsonData));
      localStorage.setItem("windToken", responseJsonData.meta.token);
      localStorage.setItem("windOnboardingDone",JSON.stringify(false));
      
      this.setState({
        signUpSuccess:true
      })
      const time = setTimeout(()=>{
          this.props.navigation.navigate("EmailAccountLoginBlock", {
            data: responseJsonData,replace:true
          });
          clearTimeout(time);
      },6000);
      
      history.pushState(null,"",location.href);
        window.onpopstate=()=>{
          history.go(1)
        }

    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJsonData);
    }
    this.parseApiCatchErrorResponse(errorResponseData);
  }
  countryCodeMessageMethod=(selectedCode:any)=>{
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode,
      });
    }
  }
  otpAuthTknMethod=(otpAuthToken:any, messageId:any)=>{
    if (otpAuthToken && otpAuthToken.length > 0) {
      this.setState({ otpAuthToken: otpAuthToken });
      runEngine.debugLog("otpAuthToken", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [messageId]);
    }
  }
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.updateCarModels();
    this.stateCallApi("Us");
    this.cityCallapi("al", "us");
    this.termsAndConditionsApiCall();
  }

  updateCarModels() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.carsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/bx_block_categories/cars/dropdown_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getCarMake = () => {
    return Object.keys(this.state.carModels);
  }
  getCarModel = (makeof:any) => {
    return Object.keys(this.state.carModels[makeof][0]);
  }
  getCarYear = (makeof:any, model:any) => {
    return Object.keys(this.state.carModels[makeof][0][model]);
  }
  getCarBatterySize = (makeof:any, model:any, year:any)=>{
    return this.state.carModels[makeof][0][model][year];
  }

  checkUsernameExist(name:string) {
      console.log("name in api call =>>>>>>>>>", name);
    const header = {
      "Content-Type": configJSON.carsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkUsernameExistId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/account_block/accounts/check_user_name_validation?user_name=${name}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  cityCallapi = (stateCode:string, countryCode:string) => {
    this.setState({
      location: {
        stateCode: stateCode,
        countryCode: countryCode
      }
    });
    const header = {
      "Content-Type": configJSON.carsApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cityCallApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/bx_block_states_cities/state_cities/cities?alpha_code=${stateCode}&country_code=${countryCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  zipcodeApiCall = (code:string) => {
    
    const header = {
      "Content-Type": configJSON.carsApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.zipcodeapiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/bx_block_states_cities/state_cities/zipcode_validation?zipcode=${code}&city=${this.state.city}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  termsAndConditionsApiCall = () => {
    
    const header = {
      "Content-Type": configJSON.carsApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsAndConditionsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndConditionEndPoint
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  
  }

  stateCallApi = (countryCode:string) => {
    const header = {
      "Content-Type": configJSON.carsApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.stateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/bx_block_states_cities/state_cities/states?country_code=${countryCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  checkEmailExist(mail:string) {

    const header = {
      "Content-Type": configJSON.carsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkEmailExistId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/account_block/accounts/check_email_validation?email=${mail}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  validateOTP(otp:string) {

    const header = {
      "Content-Type": configJSON.carsApiContentType,
      token: localStorage.getItem("vToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateOTPId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/bx_block_forgot_password/otp_confirmations`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    const data = {
      otp_code: otp
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createAccount = () => {
    this.setState({
      usernameError: this.isStringNullOrBlank(this.state.username) && this.state.usernameError,
      cityError: this.isStringNullOrBlank(this.state.city),
      emailError: this.isStringNullOrBlank(this.state.email) && this.state.emailError,
      passwordError: this.isStringNullOrBlank(this.state.password) && this.state.passwordError,
      repasswordError: this.isStringNullOrBlank(this.state.reTypePassword) && this.state.repasswordError,
      streeaddressError: this.isStringNullOrBlank(this.state.streetAddress),
      carnameError: this.isStringNullOrBlank(this.state.carName) && this.state.carnameError,
      zipError: this.isStringNullOrBlank(this.state.zipCode) && this.state.zipCodeError,
      carmakeError: this.isStringNullOrBlank(this.state.electricCarMake),
      carmodelError: this.isStringNullOrBlank(this.state.electricCarModel),
      caryearError: this.isStringNullOrBlank(this.state.electricCarYear),
      questionError: this.isStringNullOrBlank(this.state.overNightChargeType.toString()) && this.state.questionError,
      batterySizeError: this.isStringNullOrBlank(this.state.carBatterySize),
      kwError:this.isStringNullOrBlank(this.state.electricCarkWh.toString()),
      
    });

    console.log("------> ", this.state.electricCarMake, this.state.electricCarModel, this.state.electricCarYear.toString());

    if (
      this.isStringNullOrBlank(this.state.username) ||
      this.isStringNullOrBlank(this.state.city) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword) ||
      this.isStringNullOrBlank(this.state.streetAddress) ||
      this.isStringNullOrBlank(this.state.carName) ||
      this.isStringNullOrBlank(this.state.zipCode) ||
      this.isStringNullOrBlank(this.state.electricCarMake) ||
      this.isStringNullOrBlank(this.state.electricCarModel) ||
      this.isStringNullOrBlank(this.state.electricCarYear) ||
      this.isStringNullOrBlank(this.state.electricCarkWh.toString()) ||
      this.isStringNullOrBlank(this.state.carBatterySize) ||
      this.isStringNullOrBlank(this.state.overNightChargeType.toString())
    ) {
      if (!this.state.questionError) {
        this.setState({questionErrorMsg:true})
      }
      return false;
    }

    // var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
    //   this.state.countryCodeSelected,
    //   this.state.phone
    // );

    // if (phoneNumberError) {
    //   this.showAlert(configJSON.errorTitle, phoneNumberError);
    //   return false;
    // }

    // if (!this.isValidEmail(this.state.email)) {
    //   this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
    //   return false;
    // }

    // if (!this.passwordReg.test(this.state.password)) {
    //   this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
    //   return false;
    // }

    // if (this.state.password !== this.state.reTypePassword) {
    //   this.showAlert(
    //     configJSON.errorTitle,
    //     configJSON.errorBothPasswordsNotSame
    //   );
    //   return false;
    // }
    
    if (!this.state.termandcondition) {
      this.setState({checkTC:true})
      // this.showAlert("Required", "Term and condition should agreed!");
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      email: this.state.email,
      user_name: this.state.username,
      password: this.state.password,
      question_choice_type: this.state.overNightChargeType,
      battery_size:this.state.carBatterySize,
      address_attributes: {
        address: this.state.streetAddress,
        city: this.state.city,
        state_or_province: this.state.stateProvince,
        zipcode: this.state.zipCode,
      },
      car_attributes: {
        car_name: this.state.carName,
        electric_car_model: this.state.electricCarModel,
        maximum_km: this.state.electricCarkWh,
        electric_car_year: this.state.electricCarYear,
        electric_car_make: this.state.electricCarMake,
      },
      answer_attributes: [],
    };

    // const attrs = {
    //   email: "kuamr3@gmail.com",
    //   user_name: "kumar3@123",
    //   password: "password",
    //   password_confirmation: "password",
    //   addresses_attributes: {
    //     address: "ttt",
    //     city: "abc",
    //     state_or_province: "ireland",
    //     zipcode: "232334443",
    //   },
    //   car_attributes: {
    //     car_name: "abc",
    //     electric_car_model: "alto",
    //     maximum_km: "1",
    //     electric_car_year: "200",
    //     electric_car_make: "yes",
    //   },

    //   answer_attributes: [
    //     {
    //       question_id: 12,
    //       answer: ["aa", "ddd"],
    //     },
    //   ],
    // };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  // Customizable Area End
}
