import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { COTABS, TABS } from "../../../components/src/Constants";
import Colors from "../../../components/src/ColorsCodes";
import CONSTANTS from '../../../components/src/Constant';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any;
  token: any;
  name:any
  activeTab: { name: string, id: number ,analytics:string },
  activeCOTab:any
  isOn:boolean
  badgets:any
  showPopupMenu:boolean
  text:any
  tintcolor:any
  desc:any
  alertimage:any
  notify:any
  text2:any
  unreadCount:number
  GPA:any
  GPA2:any
  CO2:any
  labelsgpa1:any
  datagpa1:any
  labelsgpa2:any
  datagpa2:any
  labelsgpa3:any
  datagpa4:any
  datagpa5:any
  labelsgpa5:any
  gridconusmption:any
  saran:any
  shareGPAModal:boolean
  shareCO2Modal:boolean
  sharingData:string;
  sharingImageURL:string;
  loadingData:boolean;
  loadingDataCO2:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;
  focusListener: any;
  apiFetchsNotificationsCallId:any;
  apiVisualAnalyticsGPAsCallId:any;
  apiVisualAnalyticsCO2CallId:any;
  apiVisualAnalyticsShareGPAId:any;
  apiVisualAnalyticsShareCO2Id:any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      name:"",
      activeTab: TABS[0],
      activeCOTab: COTABS[0],
      isOn:false,
      token: null,
      badgets : [{}],
      tintcolor:Colors.secondary,
      showPopupMenu:false,
      text:"",
      desc:"",
      text2:".",
      alertimage:"",
      notify:"",
      unreadCount:0,
      GPA:[],
      GPA2:[],
      CO2:[],
      labelsgpa1:[],
      datagpa1:[],
      labelsgpa2:[],
      datagpa2:[],
      labelsgpa3:[],
      datagpa4:[],
      data: [],
      gridconusmption:[],
      datagpa5:[],
      labelsgpa5:[],
      saran:"",
      shareGPAModal:false,
      shareCO2Modal:false,
      sharingData:"",
      sharingImageURL:"",
      loadingData:true,
      loadingDataCO2:true
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token })
    } 
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
       
      if(apiRequestCallId && responseJson && !responseJson.errors && responseJson !== undefined){ 
      switch(apiRequestCallId){
        case this.apiGetDataCallId:
            this.setState({
              badgets : responseJson
            })
        break;
        case this.apiFetchsNotificationsCallId:
          this.setState({unreadCount:responseJson.meta.unread_count})
          localStorage.setItem("unreadCount",JSON.stringify(responseJson?.meta?.unread_count));
        break;
        case this.apiVisualAnalyticsGPAsCallId:
            this.setState({GPA:responseJson.demo_gpa})
            this.setState({GPA2:responseJson.user_gpa})
            let labels :any=[]
            let data45 :any = []
            responseJson?.demo_gpa.forEach(function(val:any) {
                Object.entries(val).forEach(function([key, value]) {
                  labels.push(key)
                  data45.push(value)
                }) 
              })
              this.setState ({labelsgpa1: labels,datagpa1: data45}) 
              let labels2 :any=[]
              let data452 :any = []
              responseJson?.user_gpa.forEach(function(val:any) {
                Object.entries(val).forEach(function([key, value]) {
                  labels2.push(key)
                  data452.push(value) 
                }) 
              })
              this.setState ({labelsgpa2: labels2,datagpa2: data452,loadingData:false})
        break;
        case this.apiVisualAnalyticsCO2CallId:
          this.setState({gridconusmption:responseJson.response.grid_consumption_records,CO2:responseJson.response.battery_percentage_records})
          let labels1 :any=[]
          let data457 :any = []
          responseJson?.response?.battery_percentage_records.forEach(function(val:any) {
              Object.entries(val).forEach(function([keys, values]) {
                labels1.push(keys)
                data457.push([values]) 
              }) 
            })
            this.setState ({labelsgpa3: labels1,datagpa4: data457}) 
            this.setState({data: {
              monthly: {
                labels: this.state.labelsgpa3,
                data: this.state.datagpa4,
                barColors: ["#196F3D"],
              },
            },})
            let labelsrecord :any=[]
            let data456 :any = []
            responseJson?.response?.grid_consumption_records.forEach(function(val:any) {
                Object.entries(val).forEach(function([key1, value1]) {
                  labelsrecord.push(key1)
                  data456.push([value1])
                }) 
              })
              this.setState ({labelsgpa5: labelsrecord,datagpa5: data456,loadingDataCO2:false}) 
        break;
        case this.apiVisualAnalyticsShareCO2Id:
          this.setState({sharingData:responseJson?.url})
         break;
        case this.apiVisualAnalyticsShareGPAId:
          this.setState({sharingImageURL:responseJson?.url})
         break;
        default:
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        break;
      }
    } else if(responseJson.errors && responseJson.errors[0].token !== undefined){
      localStorage.removeItem("windUser");
      localStorage.removeItem("windToken");
      this.props.navigation.navigate("EmailAccountLoginBlock"); 
    }
  }
    
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.dayAudience();
    this.getVisualAnalyticsCO2()
    this.getVisualAnalyticsGPA2()

    this.focusListener = this.props.navigation.addListener("didFocus", async () => {
      this.fetchNotification();
    });
    // Customizable Area Start
    this.fetchNotification();
    // Customizable Area End
  }
  async componentWillUnmount() {
    this.focusListener.remove(); 
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    )
    this.send(msg);
  }

  // Customizable Area Start
 
  dayAudience = async () => {
    const token = localStorage.getItem("windToken");
    
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gamificationAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
fetchNotification = async () => {
    const token = localStorage.getItem("windToken");
  const header = {
    "Content-Type": configJSON.ApiContentType,
    token: token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiFetchsNotificationsCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${CONSTANTS?.URL?.GET_NOTIFICATIONS}`
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  )
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethodType
  )
  runEngine.sendMessage(requestMessage.id, requestMessage)
}
notifications=()=>{
  this.props.navigation.navigate('Notifications')
}
onTab2Click = (item: { name: string, id: number ,analytics:string },) => {
  this.setState({ activeTab: item },()=>{
    this.setState({
      labelsgpa1:[],
      labelsgpa2:[],
      datagpa1:[],
      datagpa2:[],
      loadingData:true,
    })
    this.getVisualAnalyticsGPA2()
  });
 }
onTab4Click = async(item: { name: string, id: number ,params:string },) => {
  localStorage.setItem("paramsvalues",item?.params);  
  this.setState({ activeCOTab: item ,saran:item?.params},() =>
  {
    this.setState({
      labelsgpa3:[],
      labelsgpa5:[],
      datagpa4:[],
      datagpa5:[],
      loadingDataCO2:true,
    })
    this.getVisualAnalyticsCO2()
  });
 
}

getVisualAnalyticsGPA2 = async () => {
    const token = localStorage.getItem("windToken");
    const header = {
    "Content-Type": configJSON.ApiContentType,
    token: token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiVisualAnalyticsGPAsCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.visualGPAStatsAPIEndPoint}?analytics[interval]=${this.state.activeTab.analytics}` 
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethodType
  )
  runEngine.sendMessage(requestMessage.id, requestMessage)
}

getVisualAnalyticsCO2 = async () => {
  const token = localStorage.getItem("windToken");
    const values = localStorage.getItem("paramsvalues")
  const header = {
    "Content-Type": configJSON.ApiContentType,
    token: token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiVisualAnalyticsCO2CallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.visualCO2StatsAPIEndPoint}?analytics[interval]=${values}` 
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  )
  runEngine.sendMessage(requestMessage.id, requestMessage)
  
}
getVisualAnalyticsShareGPA = () => {
  const token = localStorage.getItem("windToken");
  const values = localStorage.getItem("paramsvalues")
  const header = {
    "Content-Type": configJSON.ApiContentType,
    token: token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiVisualAnalyticsShareGPAId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `https://c5bd-20-207-125-94.ngrok-free.app/bx_block_analytics9/co2_visual_analytics?analytics[interval]=${values}&analytics[for_share]=true` 
  )
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  )
  runEngine.sendMessage(requestMessage.id, requestMessage)
  
}
getVisualAnalyticsShareCO2 = () => {
  const token = localStorage.getItem("windToken");
  const values = localStorage.getItem("paramsvalues")
  const header = {
    "Content-Type": configJSON.ApiContentType,
    token: token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiVisualAnalyticsShareCO2Id = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `https://c5bd-20-207-125-94.ngrok-free.app/bx_block_analytics9/co2_visual_analytics?analytics[interval]=${values}&analytics[for_share]=true` 
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  )
  runEngine.sendMessage(requestMessage.id, requestMessage)
  
}

}
  // Customizable Area End
