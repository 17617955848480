import React, { useState } from "react";
// Customizable Area Start

import Box from "@material-ui/core/Box";
import '../css/profile.css'
import {
  Container,
  Button,
  Divider,
  Select,
  TextField,
  Typography,
  Paper,
  Grid,
  Snackbar,
  Modal,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton
} from "@material-ui/core";
import CoustomTextInput from "../../email-account-registration/src/CoustomTextInput.web";
import CoustomSelectInput from "../../email-account-registration/src/CoustomSelectInput.web";
import InputLabel from "@material-ui/core/InputLabel";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";

import Tooltip from "@material-ui/core/Tooltip";
import { Theme,withStyles, } from "@material-ui/core/styles";
import DropDown from "../../email-account-registration/src/DropDown.web";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Alert from "@material-ui/lab/Alert";
import { closeModalIcon,deleteMessageIcon,downloadMessageIcon,downloadIcon,iIcon } from "./assets";
// Customizable Area End

// Customizable Area Start
const classes: any = {
  container: {
    width: "93%",
    padding: "10px",
    borderRadius: "10px"
  },
  heading: {
    marginBottom: "20px",
    color: "#336699",
  },
  subHeading: {
    display: "inline",
    fontSize: "14px",
    color: "#336699",
  },
  formContainer: {
    width: "100%",
    display: "flex",
    gap: 20,
    marginTop: "20px",
  },
  radioContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft:"20px",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    
    gap: 10,
  },
  button: {
    backgroundColor: "#006633",
    color: "white",
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: '1rem',
  },
  deleteButton: {
    backgroundColor: "#e63e3e",
    color: "white",
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: '1rem',
  },
  downloadDataButton:{
    backgroundColor: "rgb(255, 255, 255)",
    color: "black",
    fontWeight: "bolder",
    textTransform: "none",
    borderRadius: "1rem",
    textDecoration:"underline",
    marginBotton:"5px",
    marginTop:"5px"
  },
  buttonoutlined: {
    color: "#336699",
    fontWeight: "bold",
    textTransform: "none",
  },
  inputContainer: {
    marginBottom: 10,
    width:"100%",
    marginTop: 10
  },
  label: {
    marginBottom: "5px",
    marginLeft:"20px",
    marginTop:"10px",
    color: 'black'
  },
  changePasswordBtn: {
    borderRadius: '1rem',
    color: "#336699",
    border: "2px solid #336699",
    fontWeight: "bold",
    textTransform: "none",
  },
  deleteYesButton: {
    borderRadius: '1rem',
    color: "#336699",
    border: "2px solid #336699",
    fontWeight: "bold",
    textTransform: "none",
    width: "32%",
    marginLeft: "34%",
  },
  downloadYesButton:{
    borderRadius: "1.2rem",
    color: "#336699",
    border: "1px solid #336699",
    fontWeight: "bold",
    textTransform: "none",
    width: "32%",
    marginLeft: "34%",
    marginTop: "8%",
  },
  skipButton: {
    borderRadius: '1.2rem',
    color: "#336699",
    border: "1px solid #336699",
    fontWeight: "bold",
    textTransform: "none",
    width: "65%",
    marginLeft: "10%",
  },
  SubmitFeedbackBtn: {
    borderRadius: '1rem',
    color: "#ffffff",
    backgroundColor:"#336699",
    fontWeight: "bold",
    textTransform: "none",
    width: "65%",
    marginLeft: "21%",
  },
  divider: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.55)'
  },
  questionTitle: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginBottom: "5px",
    color: 'black',
    width:'100%'
  },
  questionTitle2: {
    color: 'black',
    width:'100%',
    marginBottom:"-10px",
    maringTop:"-5px"
  },
  formContent: {
    padding: '1rem'
  },
  
};
const LightTooltip : any = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "red",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  tooltipPlacementBottom: {
    marginTop: "-10px ",
  },
}))(Tooltip);
// Customizable Area End

export default class Profile extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  usernameValidater = (setShowError: any, value?: string) => {
    // the input firstname should only contains characters A-Z, a-z, and -
    // the input login name should only contains alphanumeric characters
    const pattern = /^[a-zA-Z ]*$/
    this.checkUsernameExist(value!);
    const time  = setTimeout(() => {
      if(!pattern.test(this.state.username)){
        this.setState({
          usernameError: true,
          usernameErrorMsg:"Username is not valid use only lowercase, uppercase and digits"
        })
      }else if(this.state.usernameError){
        this.setState({
          usernameError: true,
          usernameErrorMsg:"Username already exists, please choose a different username"
        });
      } else if(this.state.username.trim()===""){
        this.setState({
          usernameError: true,
          usernameErrorMsg:"Username is mandatory"
        });
      }
      else{
        this.setState({
          usernameError:false,
          usernameErrorMsg:"",
        })
      }
      clearTimeout(time);
    }, 1000);
  }

  updateUsername = (value: any) => {
    this.setState({
      username: value,
    });
  };

  emailValidater = (setShowError: any) => {
    const pattren = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (pattren.test(this.state.email)) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  };
  updateComfortLevel = (value: any) => {
    this.setState({
      comfortLevel: value
    });
  }

  alwaysShowValidation = (setShowError: any) => {
    setShowError(true);
  }
  checkComfortValidation = (setShowError:any)=>{
    const pattern = /^\d+$/;
    console.log("Check Comfort", parseInt(this.state.comfortLevel))
    const timer = setTimeout(()=>{
      if(parseInt(this.state.comfortLevel) < 0 || parseInt(this.state.comfortLevel)>9){
        this.setState({
          comfortLevelError:true,
          comfortLevelErrorMsg:"Comfort Level should be in 0-9 range"
        })
      }else if(!pattern.test(this.state.comfortLevel)){
        this.setState({
          comfortLevelError:true,
          comfortLevelErrorMsg:"Only numeric values are allowed."
        })
      } 
      else{
       this.setState({
          comfortLevelError:false,
          comfortLevelErrorMsg:""
        })
      }
      clearTimeout(timer);
    },1000);
  }
  updateComteDistance = (value: any) => {
    this.setState({
      comuteDistance: value
    });
  }

  updateDrivingExperience = (value: any) => {
    this.setState({
      drivingExperience: value
    });
  }

  updateEmail = (value: any) => {
    this.setState({
      email: value,
    });
  };

  updateLowCarbon = (value: any) => {
    this.setState({
      lowCarbon: value
    });
  }

  updateDropDown = (value: any, index: number) => {

    const list = [...this.state.questionList];
    list[index].attributes.answer = [value];
    this.setState({
      questionList: list,
    });

  }

  updatePasswrord = (value: any) => {
    this.setState({
      password: value,
    });
  };

  updateRePassword = (value: any) => {
    this.setState({
      reTypePassword: value,
    });
  };

  passwordValidater = (setShowError: any) => {
    //To check a password between 7 to 16 characters which contain only characters, numeric digits, underscore and first character must be a letter
    const pattren = /^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[!@#\$%\^&*])(?=.{8,})/;
    if (pattren.test(this.state.password)) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  updateAddress = (value: any) => {
    this.setState({
      streetAddress: value,
    });
  };
  addressValidater = (setShowError:any, value?:string) => {
    this.setState({
      streetAddressError : value!.length === 0
    });
  }

  updateState = (value: any) => {
    this.setState({
      stateProvince: value,
    });
  };


  updateZipCode = (value: string) => {
    this.setState({
      zipcode: value,
    });

  };

  zipcodeValidater = (setShowError:any, value?:string) => {
    
    this.zipcodeApiCall(value!);
    const time  = setTimeout(() => {
      if(this.state.zipcode.length === 0){
        this.setState({
          zipCodeError: true,
          zipErrorMsg:"Zipcode is mandatory"
        });
      }else if(this.state.zipCodeError){
        this.setState({
          zipCodeError: true,
          zipErrorMsg:"Zipcode is not valid"
        });
      }else{
        this.setState({
          zipCodeError:false,
          zipErrorMsg:"",
        })
      }
      clearTimeout(time);
    }, 1000);
  }

  updateCarName = (value: any) => {
    this.setState({
      carName: value,
    });
  };

  carnameValidater = (setShowError:any, value?:string) => {
    const pattern = /^[a-zA-Z][a-zA-Z0-9!-]+$/;
    if(!pattern.test(this.state.carName)){
    
      this.setState({
        carNameError: true,
        carNameErrorMsg: "Letters, numbers, dashes and exclamation points only"
      });
    }else if(this.state.carName.trim()===""){
      this.setState({
        carNameError: true,
        carNameErrorMsg: "Car name is mandatory"
      });
    } 
    else {
      this.setState({
        carNameError : false,
        carNameErrorMsg:""
      });
    }  
  }

  
  updateOverNightCharger = (event: any,value:any) => {
    this.setState({
      overNightCharger: value,
    });
  };

  handleDeleteMessageClose=()=>{
    this.setState({
      enableDeleteMessage:false
    })
  }
  openFeedbackForm=()=>{
    this.setState({
      enableDeleteMessage:false,
      enableFeedbackModal:true
    })
    this.getFeedbackOptions();
  }
  openDownloadForm=()=>{
    this.setState({
      downloadProfileData:true
    })
  }
  closeDownloadForm=()=>{
    this.setState({
      downloadProfileData:false
    })
  }
  handleFeedbackClose=()=>{
    this.setState({
      enableFeedbackModal:false
    })
  }
  downloadProfile=()=>{
    this.downloadAPICallMethod();
    this.closeDownloadForm();
  }
  updateFeedbackOption=(event: any,value:any)=>{
    this.setState({
      selectedFeedback:value
    })
  }
  updateFeedbackText=(event:any)=>{
    this.setState({
      otherFeedback:event.target.value
    })
  }
  updateKW = (value: any) => {
    this.setState({
      electricCarkWh: value,
    });
  };

  kwValidater = (setShowError:any, value?:any) => {
    const pattern = /^[1-9](?:\d{1,2})?\.?(?:\d{1,2})$/
    if(value>999 || value<=0){
      setShowError(true);
      this.setState({
        kwError: true,
        kwErrorMsg: "kW value should be in a range of (1-999), in numeric only"
      })
    }else if(!pattern.test(value)){
      this.setState({
        kwError: true,
        kwErrorMsg:"Only numeric values should be entered, till 2 decimal places are allowed."
      })
    }
    else{
      this.setState({
        kwError: false,
        kwErrorMsg:""
      })
    }
  }
  
  // Customizable Area End

  render() {
    return (


      // Customizable Area Start
      <Container maxWidth="xl">
        { 
             this.state.enableDeleteMessage ? (
             <Grid item xs={12} md={12}>
              <Modal open={this.state.enableDeleteMessage} onClose={this.handleDeleteMessageClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="deleteMessageBox">
                <img src={closeModalIcon} id="modal-modal-title" alt="Information" className="closeDeleteMessageIcon" onClick={this.handleDeleteMessageClose} ></img>
                  <img src={deleteMessageIcon} id="modal-modal-title" alt="Information" className="deleteMessageIcon"></img>  
                  <Typography id="modal-modal-title" className="deleteMessageTitleInfo" variant="h6">
                    Are you sure you want to delete your information?
                  </Typography>
                  <Typography id="modal-modal-description" className="deleteMessageInfo" variant="body2">
                    If you do so, you will be logged out and your data will be deleted.
                  </Typography>
                  <Button fullWidth variant="outlined" style={classes.deleteYesButton} onClick={this.openFeedbackForm}>Yes, delete</Button>
                  <Typography variant="body1" className="cancelOption" onClick={this.handleDeleteMessageClose}>Cancel</Typography>     
                </Box>
              </Modal>
             </Grid>
            )
             : 
             ( <></>)
          }
          { 
             this.state.downloadProfileData ? (
             <Grid item xs={12} md={12}>
              <Modal open={this.state.downloadProfileData} onClose={this.closeDownloadForm} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="deleteMessageBox">
                <img src={closeModalIcon} id="modal-modal-title" alt="Information" className="closeDeleteMessageIcon" onClick={this.closeDownloadForm} ></img>
                  <img src={downloadMessageIcon} id="modal-modal-title" alt="Information" className="downloadMessageIcon"></img>  
                  <Typography id="modal-modal-title" className="downloadMessageTitleInfo" variant="h6">
                    Click here to get your data
                  </Typography>
                  <Button fullWidth variant="outlined" style={classes.downloadYesButton} onClick={this.downloadProfile}>Yes, download</Button>
                  <Typography variant="body1" className="cancelOption" onClick={this.closeDownloadForm}>Cancel</Typography>     
                </Box>
              </Modal>
             </Grid>
            )
             : 
             ( <></>)
          }
          { 
             this.state.enableFeedbackModal ? (
             <Grid item xs={12} md={12}>
              <Modal open={this.state.enableFeedbackModal} onClose={this.handleFeedbackClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="feedBackBox">
                <img src={closeModalIcon} id="modal-modal-title" alt="Information" className="closeDeleteMessageIcon" onClick={this.handleFeedbackClose} ></img>
                  <Typography id="modal-modal-title" className="feedbackTitleInfo" variant="h6">
                    I am deleting the account because:
                  </Typography>
                  <RadioGroup className="radioFeedbacks">
                  {this.state.feedbackOptions.map((item: any, index: any) => (
                     <FormControlLabel
                     key={index}
                     label={item}
                     className="radioGroupFormLabel"
                     control={
                       <Radio
                       className="chatFilterItem"
                         onChange={(e:any) => {
                          this.updateFeedbackOption(e,item)
                         }}
                         color="primary"
                         size="small"
                         checked={this.state.selectedFeedback===item}
                       />
                     }
                   /> 
                     ))}
                  </RadioGroup>
                  <Typography className="otherTypography">Other:</Typography>
                  <TextField
                  test-id="addOtherTestInput"
                  className="addOtherInput"
                  onChange={this.updateFeedbackText}
                />
                 <Grid container className="gridContainerButtons">
                  <Grid item xs={4} md={6}>
                  <Button fullWidth variant="text" style={classes.SubmitFeedbackBtn} onClick={this.sendFeedback}>Submit</Button>
                  </Grid>
                  <Grid item xs={4} md={6}>
                  <Button fullWidth variant="outlined" style={classes.skipButton} onClick={this.handleFeedbackClose}>Skip</Button>
                  </Grid>
                 </Grid>
                </Box>
              </Modal>
             </Grid>
            )
             : 
             ( <></>)
          }
        <Grid item xs={12} className="root">
          <Paper elevation={2} style={classes.container}>
            <Grid item xs={12} style={{paddingLeft: "1rem"}}>
              <Typography variant="h5" style={classes.heading} display="inline">
                {`${this.state.username} | `}
              </Typography>
              <Typography variant="body1" style={classes.heading} display="inline">
                Profile
              </Typography>
              <Snackbar open={this.state.successMsg} autoHideDuration={6000} onClose={() => this.setState({ successMsg:false })}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert severity="success">Changes were saved Successfully!!</Alert>
              </Snackbar>
            </Grid>
            
            <Grid item xs={12}>
              <Grid container direction="row" spacing={3} style={classes.formContent}>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item xs={12}>
                      <CoustomTextInput
                        label={"Username*"}
                        style={classes.label}
                        updateText={this.updateUsername}
                        validater={this.usernameValidater}
                        value={this.state.username}
                        errorMssage={""}
                        showError={this.state.usernameError}
                        alertMsg={this.state.usernameErrorMsg}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CoustomTextInput
                        label={"Email*"}
                        updateText={this.updateEmail}
                        validater={this.emailValidater}
                        value={this.state.email}
                        errorMssage={"Email is not valid"}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CoustomTextInput
                        label={"Street Address*"}
                        updateText={this.updateAddress}
                        validater={this.addressValidater}
                        value={this.state.streetAddress}
                        errorMssage={""}
                        alertMsg={"Street Address is mandatory"}
                        showError={this.state.streetAddressError}
                        data-test-id="addresstestfield"
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <div style={{flexDirection: "column", width: "100%",gap:"2px"}}>
                  <InputLabel
                  style={{color: 'black',marginTop:"10px", marginLeft:"20px"}}
                  >
                    State or Province*
                  </InputLabel>
                  <div className="wrapper">
                     <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.stateProvince}
                        style={{borderRadius: 20, width: "100%",
                        paddingRight:"20px",
                         backgroundColor:"white",
                         cursor:"pointer"
                         }}
                        variant="outlined"
                        IconComponent={KeyboardArrowDownIcon}
                        >
                      <option value={""}></option>
                      {this.state.stateProvinceList.map((item:any, index:number) => {
                        return (
                          <option
                          style={{
                            border: "1px solid white",
                            borderRadius:"5px",
                            height: "20px",
                            paddingLeft:"25px",
                            cursor:"pointer" 
                          }}
                            value={item.name}
                            onClick={(e) => {this.setState({stateProvince: item.name});
                                    console.log(item, "in option clicked")
                                    this.cityCallapi(item.alpha_code,item.country_code);
                                  }}
                                    aria-labelledby="dropDownCityMenu"
                          >
                            {item.name}
                          </option>
                        );
                      })}
                      </Select>
                   </div>
                  </div>
                
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ flexDirection: "column", width: "100%",gap:"2px" }}>
                        <InputLabel style={classes.label}>City*</InputLabel>
                        <div className="wrapper" style={{height:"40px"}}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.city}
                          style={{borderRadius: 20, width: "100%",
                          paddingRight:"20px",
                          backgroundColor:"white",
                          color:"grey",
                          cursor:"pointer"
                        }}
                          variant="outlined"
                          IconComponent={KeyboardArrowDownIcon}
                          data-test-id="cityDropDownTest"
                        >
                          <option value={this.state.city}>{this.state.city}</option>
                          {this.state.cityList.map(
                            (item: any, id: number) => {
                              return (
                                <option
                                  key={id}
                                  style={{
                                    border: "1px solid white",
                                    borderRadius:"5px",
                                    height: "20px",
                                    paddingLeft:"25px",
                                    cursor:"pointer" 
                                  }}
                                  value={item.name}
                                  onClick={(e) => {this.setState({city: item.name});
                                    console.log(item, "in option clicked")}}
                                    aria-labelledby="dropDownCityMenu"
                                >
                                  {item.name}
                                </option>
                              );
                            }
                          )}
                    </Select>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <CoustomTextInput
                        label={"Zip code*"}
                        showIcon
                        updateText={this.updateZipCode}
                        validater={this.zipcodeValidater}
                        value={this.state.zipcode}
                        showError={this.state.zipCodeError}
                        alertMsg={this.state.zipErrorMsg}
                        errorMssage={`Provide zip code or postal code of address that your car is parked at overnight for charging, ${this.state.zipCodeError ? "You entered invalid zipcode " + this.state.zipcode : ""}`}
                        data-test-id="zipcodetestfield"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CoustomTextInput
                        label={"Car name*"}
                        showIcon
                        updateText={this.updateCarName}
                        validater={this.carnameValidater}
                        value={this.state.carName}
                        errorMssage={"Letters, numbers, dashes and exclamation points only"}
                        alertMsg={this.state.carNameErrorMsg}
                        showError={this.state.carNameError}
                        data-test-id="carnametestfield"
                      />

                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ flexDirection: "column", width: "100%",gap:"2px" }}>
                        <InputLabel style={classes.label}>Electric car Make*</InputLabel>
                        <div className="wrapper" style={{height:"40px"}}>
                        
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.electricCarMake}
                          style={{borderRadius: 20, width: "100%",
                          paddingRight:"20px",
                          backgroundColor:"white",
                          color:"grey",
                          }}
                          variant="outlined"
                          IconComponent={KeyboardArrowDownIcon}
                        >
                          {this.state.carBrand.map(
                            (item: any, index: number) => {
                              return (
                                <option
                                style={{
                                  border: "1px solid white",
                                  borderRadius:"5px",
                                  height: "20px",
                                  paddingLeft:"25px",
                                  cursor:"pointer" 
                                }}
                                  value={item}
                                  onClick={(e) => {this.setState({electricCarMake: item,carModel: this.getCarModel(item)})}}
                   
                                >
                                  {item}
                                </option>
                              );
                            }
                          )}
                        {/* </select>
                        <KeyboardArrowDownIcon className="arrow"/> */}
                        </Select>
                        </div>
                      </div>

                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ flexDirection: "column", width: "100%",gap:"2px" }}>
                        <InputLabel style={classes.label}>Electric car Model*</InputLabel>
                        <div className="wrapper" style={{height:"40px"}}>
                        
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.electricCarModel}
                          style={{borderRadius: 20, width: "100%",
                          paddingRight:"20px",
                          backgroundColor:"white",
                          color:"grey"}}
                          variant="outlined"
                          IconComponent={KeyboardArrowDownIcon}
                        >

                          {this.state.carModel.map((item: any, index: number) => {
                            return (
                              <option
                              style={{
                                border: "1px solid white",
                                borderRadius:"5px",
                                height: "20px",
                                paddingLeft:"25px",
                                cursor:"pointer" 
                              }}
                                value={item}
                                onClick={(e) => {this.setState({electricCarModel: item,  carYear: this.getCarYear(this.state.electricCarMake, item)})}}
                      
                             >
                                {item}
                              </option>
                            );
                          })}
                        </Select>
                        </div>
                      </div>

                    </Grid>
                    <Grid item xs={12} >
                      <div style={{ flexDirection: "column", width: "100%",gap:"2px" }}>
                        <InputLabel style={classes.label}>Electric car Year*</InputLabel>
                        <div className="wrapper" style={{height:"40px"}}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.electricCarYear}
                          style={{borderRadius: 20, width: "100%",
                          paddingRight:"20px",
                          backgroundColor:"white",
                          color:"grey",
                          }}
                          variant="outlined"
                          IconComponent={KeyboardArrowDownIcon}
                          >
                          {this.state.carYear.map((item: any, index: number) => {
                            return (
                              <option
                                style={{
                                  border: "1px solid white",
                                  borderRadius:"5px",
                                  height: "20px",
                                  paddingLeft:"25px",
                                  cursor:"pointer" 
                                }}
                                value={item}
                                onClick={(e) => {this.setState({electricCarYear: item,batterySize:this.getCarbatterySize(this.state.electricCarMake,this.state.electricCarModel,item)})}}
                       
                              >
                                {item}
                              </option>
                            );
                          })}
                        </Select>
                        </div>
                      </div>

                    </Grid>
                    <Grid item xs={12} style={{marginBottom:"-38px"}}>
                    <div style={{ flexDirection: "column", width: "100%",gap:"2px" }}>
                        <InputLabel style={classes.label}>Battery Size *
                        <LightTooltip title={"State the car’s battery size in kilowatt-hours (kWh).  If your car is software locked, to less than the full battery, enter the kWh accessible to you. Some Model Ss & Xs are locked to around 79 kW."} placement="bottom-end" >
                              <IconButton className="errorIconButton">
                                <img src={iIcon} alt="" className="errorIcon" />
                              </IconButton>
                        </LightTooltip>
                        </InputLabel>
                        <div className="wrapper" style={{height:"40px"}}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.carBatterySize}
                          style={{borderRadius: 20, width: "100%",
                          paddingRight:"20px",
                          backgroundColor:"white",
                          color:"grey",
                          }}
                          variant="outlined"
                          IconComponent={KeyboardArrowDownIcon}
                          >
                          {this.state.batterySize.map((item: any, index: number) => {
                            return (
                              <option
                                style={{
                                  border: "1px solid white",
                                  borderRadius:"5px",
                                  height: "20px",
                                  paddingLeft:"25px",
                                  cursor:"pointer" 
                                }}
                                value={item}
                                onClick={(e) => {this.setState({carBatterySize: item})}}
                       
                              >
                                {item}
                              </option>
                            );
                          })}
                        </Select>
                        </div>
                      </div>

                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ flexDirection: "column", width: "100%",gap:"2px" }}>
                      <CoustomTextInput
                        label="Maximum Kilowatts (kW)*"
                        value={this.state.electricCarkWh}
                        updateText={this.updateKW}
                        validater={this.kwValidater}
                        errorMssage={"This is the maximum kW your charger provides and that your car can take. You can calculate kW by multiplying amps times volts and dividing by 1,000"}
                        number
                        showIcon
                        showError={this.state.kwError}
                        alertMsg={this.state.kwErrorMsg}
                      
                      />
                      </div> 
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={1}>
                  <InputLabel style={classes.label}>My use of overnight charger is:</InputLabel>
                    <Grid xs={12} className="radioContainer1">
                      <input
                        type="radio"
                        name="radio"
                        className="radioButtons"
                        onChange={(e:any)=>this.updateOverNightCharger(e,"Answer1")}
                        checked={this.state.overNightCharger=== "Answer1"}
                        data-test-id="answer1radiotest"
                      />
                      <Typography>
                        Exclusive to the car, identified above, unless I invite a guest
                        to style over
                      </Typography>

                    </Grid>
                    <Grid xs={12} className="radioContainer1">
                      <input
                        type="radio"
                        name="radio"
                        className="radioButtons"
                        onChange={(e:any)=>this.updateOverNightCharger(e,"Answer2")}
                        checked={this.state.overNightCharger=== "Answer2"}
                        data-test-id="answer2radiotest"
                      />
                      <Typography>
                        Shared with a other driver of another electric car who lives
                        with me
                      </Typography>
                    </Grid>
                    <Grid xs={12} className="radioContainer1">
                      <input
                        type="radio"
                        name="radio"
                        className="radioButtons"
                        onChange={(e:any)=>this.updateOverNightCharger(e,"Answer3")}
                        checked={this.state.overNightCharger=== "Answer3"}
                        data-test-id="answer3radiotest"
                      />
                      <Typography>
                        Shared with a talents in a department or at other comman
                        parking area
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider
                        style={classes.divider}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {this.state.questionList.map((item: any, index: number) => {
                        switch(item.attributes.answer_type){
                          case "RadioButton":
                            return (
                              <Grid container alignItems="center" spacing={1} style={{ marginBottom: "4px" }}>
                                <InputLabel style={classes.questionTitle}>{item.attributes.content}</InputLabel>
                                {item.attributes.options.map((option: any, id: number) => {
                                  return (
                                    <Grid item xs={12} md={3} key={id}>
                                      <input
                                        type="radio"
                                        name={`radio${index}`}
                                        value={option}
                                        checked={item?.attributes?.answer?.includes(option)}
                                        
                                        onChange={(e) => {
                                          const list = [...this.state.questionList];
                                          list[index].attributes.answer = [e.target.value];
                                          this.setState({
                                            questionList: list,
                                          });
                                        }}
                                      />
                                      <Typography display="inline">{option}</Typography>
                                    </Grid>
                                  );
                                })}
                              </Grid> )
                          case "Text":
                              return (
                                <Box style={{ marginBottom: "10px" }}>
                                  <InputLabel style={classes.questionTitle}>{item.attributes.content}</InputLabel>
                                  <input
                                    style={{
                                      width: "100%",
                                      padding: "15px",
                                      borderRadius: "20px",
                                      height:"40px",
                                      color:"grey",
                                      fontSize: "15px",
                                      outline: "none",
                                     
                                    }}
                                    placeholder=""
                                    type="text"
                                    value={item.attributes.answer.toString().replace(/[^a-zA-Z ]+/g, '')}
                                    onChange={(e) => {
                                      const list = [...this.state.questionList];
                                      list[index].attributes.answer = [e.target.value];
                                      this.setState({
                                        questionList: list
                                      });
                                    }}
                                  />
      
                                </Box>
                              )
                          case "Dropdown":
                              return (
                                <DropDown
                                  value={item.attributes.answer}
                                  label={item.attributes.content}
                                  options={item.attributes?.options}
                                  index={index}
                                  updateText={this.updateDropDown}  
                                />
                              ) 
                          case "Checkbox":
                            return (
                              <Grid container alignItems="center" spacing={1} style={{ marginBottom: "10px" }}>
                                <InputLabel style={classes.questionTitle}>{item.attributes.content}</InputLabel>
                                {item.attributes.options.map((option: any, id: number) => {
                                  return (
                                    <Grid item xs={12} md={3} key={id} >
                                      <input
                                        type="checkbox"
                                        checked={item?.attributes?.answer?.includes(option)}
                                        name={`radio${index}`}
                                        value={option}
                                        onChange={(e) => {
                                          const list = [...this.state.questionList];
                                          if(e.target.checked){
                                            list[index].attributes.answer.push(e.target.value);
                                          }else {
                                            list[index].attributes.answer = list[index].attributes.answer.filter(function(value:any, index:number, arr:any){ 
                                              return value !== e.target.value;
                                          });
                                          }
                                          this.setState({
                                            questionList: list,
                                          });
                                          
                                        }}
                                      />
                                      <Typography display="inline">{option}</Typography>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            )
                          default: 
                          return null;
                        } 
                      })} 
                   </Grid>
                    <Grid item xs={12} >
                    <InputLabel style={classes.questionTitle2}>Commute Distance</InputLabel>
                      <CoustomTextInput
                        updateText={this.updateComteDistance}
                        validater={this.alwaysShowValidation}
                        value={this.state.comuteDistance}
                        errorMssage={
                          "If your commute includes a routine stop most of the time, add that to the total trip returning to home"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:"20px"}}>
                      <InputLabel style={classes.questionTitle}>Preconditioning</InputLabel>
                      <Grid container spacing={2}>
                        <Grid item sm={4} md={4} style={{ display: "flex", gap: "5px" }}>
                          <input
                           type="radio"
                            name="Preconditioning" 
                            value="None" 
                            checked={this.state?.precondition=="None"}
                            onClick={(e) => {
                            this.setState({
                              precondition: "None"
                            });
                          }} 
                          />
                          {console.log("Radio button",this.state.precondition)}
                          <Typography variant="body2">None</Typography>
                        </Grid>
                        <Grid item sm={4} md={4} style={{ display: "flex", gap: "5px" }}>
                          <input type="radio" name="Preconditioning" value="Yes" onClick={(e) => {
                            this.setState({
                              precondition: "Yes"
                            });
                          }} 
                          checked={this.state?.precondition=="Yes"}
                          />
                          <Typography variant="body2">Yes</Typography>
                        </Grid>
                        <Grid item sm={4} md={4} style={{ display: "flex", gap: "5px" }}>
                          <input type="radio"
                          name="Preconditioning" value="No" checked={this.state?.precondition=="No"}
                          onClick={(e) => {
                            this.setState({
                              precondition: "No"
                            });
                          }} />
                          <Typography variant="body2">No</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                    <InputLabel style={classes.questionTitle2}>Comfort Level</InputLabel>
                    <CoustomTextInput
                        showIcon
                        updateText={this.updateComfortLevel}
                        validater={this.checkComfortValidation}

                        value={this.state.comfortLevel}
                        showError={this.state.comfortLevelError}
                        alertMsg={this.state.comfortLevelErrorMsg}
                        errorMssage={
                          "Revise as you become more familiar with the car. Duration should not include business/vacation trips that allow you to be parked without driving. Give the number of days you could go without charging as your 'comfort level', under normal conditions at your home"
                        }
                        style={{marginLeft:"0px !important"}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <InputLabel style={classes.questionTitle2}>Experience driving electric cars</InputLabel>
                    <CoustomTextInput
                        showIcon
                        updateText={this.updateDrivingExperience}
                        validater={this.alwaysShowValidation}
                        value={this.state.drivingExperience}
                        errorMssage={
                          "Include any breaks from driving electric vehicles (EVs) in your total time (in years) of driving the EVs Best public charger option Name of business, nearby cross streets, what description you would give a stranger, in order to find it."
                        }
                        style={{marginLeft:"0px"}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <InputLabel style={classes.questionTitle2}>Other low-carbon activities</InputLabel>
                    <CoustomTextInput
                        showIcon
                        updateText={this.updateLowCarbon}
                        validater={this.alwaysShowValidation}
                        value={this.state.lowCarbon}
                        errorMssage={
                          "If you are eating less meat; walking farther from your parking space; using public transportation, etc. "
                        }
                        style={{marginLeft:"0px"}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3} style={{ textAlign: 'center', marginTop: "10px" }}>
                        <Grid item xs={12} md={5}>
                          <Button fullWidth style={classes.button} data-test-id="savechangesbutton" onClick={this.updateUserProfileApiCall}>Save new changes</Button>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Button disabled fullWidth variant="outlined" style={classes.changePasswordBtn}
                          onClick={() => {
                            this.props.navigation.navigate("CreateNewPassword");
                          }}
                          >Change Password</Button>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Grid item xs={12} md={12}>
                              <Button fullWidth style={classes.downloadDataButton} onClick={()=>this.openDownloadForm()}><img src={downloadIcon} className="downloadIconButton"/>Download user data</Button>
                          </Grid>
                          <Grid item xs={12} md={12}>
                              <Button fullWidth style={classes.deleteButton} onClick={()=>this.deleteAccount()}>Delete Account</Button>
                          </Grid>
                      </Grid>
                    </Grid>
                  </Grid>


                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid >
      </Container >

      // Customizable Area End
    );
  }
}