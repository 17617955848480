import React from "react";
import "../css/createNewAccount.css";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Snackbar,
  Modal,
  Select
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import {
  createTheme,
  ThemeProvider,
  Theme,
  withStyles
} from "@material-ui/core/styles";
import CoustomTextInput from "./CoustomTextInput.web";
import { Logo, termsIcon, iIcon } from "./assets";
import InputLabel from "@material-ui/core/InputLabel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
const configJSON = require("./config");
const MyTeme = createTheme({
  palette: {
    primary: {
      main: "#006633",
      contrastText: "#000000"
    },
    secondary: {
      main: "#FFFFFF"
    }
  }
});

const LightTooltip: any = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "red",
    boxShadow: theme.shadows[1],
    fontSize: 11
  },
  tooltipPlacementBottom: {
    marginTop: "-10px "
  }
}))(Tooltip);
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import SimpleBottomNavigation from "../../dashboard/src/Footer/index.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export default class CreateNewAccount extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.updateCarModels();
    console.log(this.state.carModels);
    // console.log(theme);
    // Customizable Area End
  }
  // Customizable Area Start

  updateUsername = (value: any) => {
    this.setState({
      username: value
    });
  };

  updateEmail = (value: any) => {
    this.setState({
      email: value
    });
  };

  updatePassword = (value: any) => {
    this.setState({
      password: value
    });
  };

  updateRePassword = (value: any) => {
    this.setState({
      reTypePassword: value
    });
  };

  updateAddress = (value: any) => {
    this.setState({
      streetAddress: value
    });
  };

  updateZipCode = (value: any) => {
    this.setState({
      zipCode: value
    });
  };

  updateCarName = (value: any) => {
    this.setState({
      carName: value
    });
  };

  toggleModelState = () => {
    this.setState({ modalState: !this.state.modalState });
  };

  handleTermandCondition = () => {
    this.setState({
      termandcondition: !this.state.termandcondition
    });
  };
  handleTermandConditionButton = () => {
    this.setState({
      termandcondition: true,
      modalState: false
    });
  };
  handleDeclineTermandConditionButton = () => {
    this.setState({
      termandcondition: false,
      modalState: false
    });
  };
  handleRadioBtn = (e: any) => {
    this.setState({
      overNightChargeType: e.target.value,
      questionError: true,
      questionErrorMsg: false
    });
  };

  emailValidater = async (setShowError: any, value?: string) => {
    const pattren = new RegExp(configJSON.emailValidPattern);
    await this.checkEmailExist(value!);
    let emailError = false;
    let emailErrorMsg = "";
    if (this.state.email.trim() === "") {
      emailError = true;
      emailErrorMsg = configJSON.mandatoryEmail;
    } else if (this.state.emailError) {
      emailError = true;
      emailErrorMsg = configJSON.allreadyExist;
    } else if (!pattren.test(this.state.email)) {
      emailError = true;
      emailErrorMsg = configJSON.invalidEmail;
    } else {
      emailError = false;
    }
      this.setState({
        emailError,
        emailErrorMsg
      });
  };

  usernameValidater = async (setShowError: any, value?: string) => {
    const pattern = new RegExp(configJSON.usernamePattern);

    // Assuming checkUsernameExist is an asynchronous function that returns a promise
    await this.checkUsernameExist(value!);

    let usernameError = false;
    let usernameErrorMsg = "";

    // Assuming this.state.username should be replaced with value
    if (!pattern.test(value!)) {
        usernameError = true;
        usernameErrorMsg = configJSON.invalidUsername;
    } else if (this.state.usernameErrorMsg !== "") {
        usernameError = true;
        usernameErrorMsg = configJSON.existUsername;
    } else if (value!.trim() === "") {
        usernameError = true;
        usernameErrorMsg = configJSON.usernameMandatory;
    }

    // Assuming this.setState is used correctly and is accessible within this context
    this.setState({
        usernameError,
        usernameErrorMsg
    });
};



  passwordValidater = (setShowError: any, value?: string) => {
    //To check a password between 7 to 16 characters which contain only characters, numeric digits, underscore and first character must be a letter
    const pattren = new RegExp(configJSON.pwdValidator);
    if (this.state.password.trim() === "") {
      this.setState({
        repasswordError: true,
        repasswordErrorMsg: configJSON.fieldRequireds
      });
    } else if (pattren.test(value!)) {
      setShowError(false);
      this.setState({
        passwordError: false,
        passwordErrorMsg: ""
      });
    } else {
      setShowError(false);
      this.setState({
        passwordError: true,
        passwordErrorMsg: configJSON.fieldRequiredMsgs
      });
    }
  };

  repasswordValidater = (setShowError: any, value?: string) => {
    if (this.state.password !== value!) {
      setShowError(true);
      this.setState({
        repasswordError: true,
        repasswordErrorMsg: configJSON.confirmPasswordMatchError
      });
    } else if (this.state.reTypePassword.trim() === "") {
      this.setState({
        repasswordError: true,
        repasswordErrorMsg: configJSON.confirmPasswordMandatory
      });
    } else {
      setShowError(false);
      this.setState({
        repasswordError: false,
        repasswordErrorMsg: ""
      });
    }
  };

  addressValidater = (setShowError: any, value?: string) => {
    this.setState({
      streeaddressError: value!.length === 0
    });
  };

  zipcodeValidater = (setShowError: any, value?: string) => {
    this.zipcodeApiCall(value!);
    let zipError = false;
    let zipErrorMsg = "";
    if (this.state.zipCodeError) {
      zipError = true;
      zipErrorMsg = configJSON.invalidZipcode;
    } else if (this.state.zipCode.length === 0) {
      zipError = true;
      zipErrorMsg = configJSON.zipCodeMandatory;
    } else {
      zipError = false;
      zipErrorMsg = "";
    } 
      this.setState({
        zipError,
        zipErrorMsg
      });
  };

  carnameValidater = () => {
    const pattern = configJSON.carNamePattern;
    if (this.state.carName.trim() === "") {
      this.setState({
        carnameError: true,
        carnameErrorMsg: configJSON.reuiredcarName
      });
    } else if (!pattern.test(this.state.carName)) {
      this.setState({
        carnameError: true,
        carnameErrorMsg: configJSON.invalidCarname
      });
    } else {
      this.setState({
        carnameError: false,
        carnameErrorMsg: ""
      });
    }
  };
  updateKW = (value: number) => {
    if (value !== 0) {
      this.setState({
        electricCarkWh: value
      });
    }
  };
  kwValidater = (setShowError: any, value?: any) => {
    const pattern = configJSON.kwValidatorPattern;
    if (!pattern.test(value)) {
      this.setState({
        kwError: true,
        kwErrorMsg: configJSON.kwerror
      });
    } else if (value > 999 || value <= 0) {
      setShowError(true);
      this.setState({
        kwError: true,
        kwErrorMsg: configJSON.invalidKw
      });
    } else {
      this.setState({
        kwError: false,
        kwErrorMsg: ""
      });
    }
  };
  renderTermsList = () => {
    const termsListItems =
      this.state.termsConditions &&
      this.state.termsConditions.map((item: any) => {
        return <Typography className="modalInfo">{item}</Typography>;
      });
    return termsListItems;
  };

  // Customizable Area End

  render() {
    console.log(this.state.electricCarMake, "make called");   
    
    return (
      // Required for all blocks
      // Customizable Area Start
      <ThemeProvider theme={MyTeme}>
        {this.state.modalState && (
          <Grid xs={12} md={12}>
            <Modal
              open={this.state.modalState}
              onClose={this.toggleModelState}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="infoBoxModal">
                <img
                  src={termsIcon}
                  id="modal-modal-title"
                  alt="Information"
                  className="infoModalIcon"
                />
                <Typography variant="h6" className="signupTermsHeading">
                  Terms and Conditions
                </Typography>
                <Grid id="modal-modal-description" className="modalInfoGrid">
                  {this.renderTermsList()}
                </Grid>
                <div className="buttonTCSignupDiv">
                  <Button
                    style={{
                      borderColor: "#336699",
                      color: "#336699",
                      borderRadius: "20px",
                      width: "30%",
                      textTransform: "none"
                    }}
                    variant="outlined"
                    data-test-id="declineTerms"
                    onClick={this.handleDeclineTermandConditionButton}
                  >
                    Decline
                  </Button>
                  <Button
                    style={{
                      borderRadius: "20px",
                      width: "30%",
                      color: "white",
                      textTransform: "none"
                    }}
                    variant="contained"
                    onClick={this.handleTermandConditionButton}
                    color="primary"
                    data-test-id="acceptTnC"
                  >
                    Accept
                  </Button>
                </div>
              </Box>
            </Modal>
          </Grid>
        )}
        <Grid container style={{ backgroundColor: "white" }}>
          <Grid item sm={12} md={12} xl={12} className="headerContainer">
            <Snackbar
              data-test-id="snackBar"
              open={this.state.checkTC}
              autoHideDuration={6000}
              onClose={() => this.setState({ checkTC: false })}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert severity="warning">
                Please accept Terms and Conditions to continue!{" "}
              </Alert>
            </Snackbar>
            <Snackbar
              data-test-id="snackBarSucess"
              open={this.state.signUpSuccess}
              autoHideDuration={6000}
              onClose={() => this.setState({ signUpSuccess: false })}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert severity="success">
                Account is created and verify in your email{" "}
              </Alert>
            </Snackbar>
            <img
              data-test-id="logoImage"
              src={Logo}
              alt=""
              className="logoImg"
              style={{ width: "250px", height: "200px" ,cursor: "pointer"}}
              onClick={() => {
                this.props.navigation.navigate("Home");
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <h2 className="heading">Create an account</h2>
            <div className="formContainer">
              <div className="flexRow">
                <CoustomTextInput
                  label={"Username*"}
                  value={this.state.username}
                  updateText={this.updateUsername}
                  validater={this.usernameValidater}
                  // errorMssage={"Username is mandatory"}
                  data-test-id="txtInputUserName"
                  showError={this.state.usernameError}
                  alertMsg={ this.state.usernameErrorMsg}
                />             
                <CoustomTextInput
                  label={"Email*"}
                  updateText={this.updateEmail}
                  validater={this.emailValidater}
                  errorMssage={
                    "Email should be in correct formate (example@domain.com)"
                  }
                  showError={this.state.emailError}
                  alertMsg={this.state.emailErrorMsg}
                  data-test-id="txtInputEmail"
                />
              </div>
              <div className="flexRow">
                <CoustomTextInput
                  password
                  label={"Password*"}
                  value={this.state.password}
                  updateText={this.updatePassword}
                  validater={this.passwordValidater}
                  errorMssage={
                    "Password should contains at least 1 uppercase, 1 lowercase, 1 special character and length should be at least 8 characters"
                  }
                  showError={this.state.passwordError}
                  alertMsg={this.state.passwordErrorMsg}
                  data-test-id="txtInputPassword"
                />
                <CoustomTextInput
                  password
                  label={"Confirm Password*"}
                  value={this.state.reTypePassword}
                  updateText={this.updateRePassword}
                  validater={this.repasswordValidater}
                  errorMssage={"Confirm password is not match with password"}
                  showError={this.state.repasswordError}
                  alertMsg={this.state.repasswordErrorMsg}
                  data-test-id="txtInputConfirmPassword"
                />
              </div>
              <div className="flexRow">
                <CoustomTextInput
                  label={"Street Address*"}
                  updateText={this.updateAddress}
                  validater={this.addressValidater}
                  errorMssage={""}
                  // showIcon
                  alertMsg={"Street Address is mandatory"}
                  showError={this.state.streeaddressError}
                />
                <div
                  style={{ flexDirection: "column", width: "100%", gap: "2px" }}
                >
                  <InputLabel
                    style={{
                      color: "black",
                      marginTop: "10px",
                      marginLeft: "20px"
                    }}
                  >
                    State or Province*
                  </InputLabel>
                  <div className="wrapper">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.stateProvince}
                      style={{
                        borderRadius: 20,
                        width: "100%",
                        paddingRight: "20px",
                        backgroundColor: "white",
                        cursor: "pointer"
                      }}
                      variant="outlined"
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <option value={""} />
                      {this.state.stateProvinceList.map((item: any) => {
                        return (
                          <option
                            style={{
                              border: "1px solid white",
                              borderRadius: "5px",
                              height: "20px",
                              paddingLeft: "25px",
                              cursor:"pointer"
                            }}
                            value={item.name}
                            onClick={() => {
                              this.setState({ stateProvince: item.name });
                              console.log(item, "in option clicked");
                              this.cityCallapi(
                                item.alpha_code,
                                item.country_code
                              );
                            }}
                            aria-labelledby="dropDownCityMenu"
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  {this.state.stateError && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      State or Province is mandatory
                    </p>
                  )}
                </div>
              </div>
              <div className="flexRow">
                <div
                  style={{ flexDirection: "column", width: "100%", gap: "2px" }}
                >
                  <InputLabel
                    style={{
                      color: "black",
                      marginTop: "10px",
                      marginLeft: "20px"
                    }}
                  >
                    City*
                  </InputLabel>
                  <div className="wrapper">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.city}
                      style={{
                        borderRadius: 20,
                        width: "100%",
                        paddingRight: "20px",
                        backgroundColor: "white",
                        cursor: "pointer"
                      }}
                      variant="outlined"
                      IconComponent={KeyboardArrowDownIcon}
                      data-test-id="cityListTest"
                    >
                      <option value={""} />
                      {this.state.cityList.map((item: any) => {
                        return (
                          <option
                            style={{
                              border: "1px solid white",
                              borderRadius: "5px",
                              height: "20px",
                              paddingLeft: "25px",
                              cursor:"pointer"
                            }}
                            value={item.name}
                            onClick={() => {
                              this.setState({ city: item.name });
                              console.log(item, "in option clicked");
                            }}
                            aria-labelledby="dropDownCityMenu"
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  {this.state.cityError && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      City is mandatory
                    </p>
                  )}
                </div>
                <CoustomTextInput
                  label={"Zip code*"}
                  updateText={this.updateZipCode}
                  validater={this.zipcodeValidater}
                  showIcon
                  showError={this.state.zipError}
                  alertMsg={this.state.zipErrorMsg}
                  errorMssage={`Provide zip code or postal code of address that your car is parked at overnight for charging, ${
                    this.state.zipCodeError
                      ? "You entered invalid zipcode " + this.state.zipCode
                      : ""
                  }`}
                />
              </div>
              <div className="flexRow">
                <CoustomTextInput
                  label={"Car name*"}
                  value={this.state.carName}
                  updateText={this.updateCarName}
                  validater={this.carnameValidater}
                  errorMssage={
                    "Letters, numbers, dashes and exclamation points only"
                  }
                  showIcon
                  alertMsg={this.state.carnameErrorMsg}
                  showError={this.state.carnameError}
                />
                <div
                  style={{ flexDirection: "column", width: "100%", gap: "2px" }}
                >
                  <InputLabel
                    style={{
                      color: "black",
                      marginTop: "10px",
                      marginLeft: "20px"
                    }}
                  >
                    Electric car Make*
                  </InputLabel>
                  <div className="wrapper">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      style={{
                        borderRadius: 20,
                        width: "100%",
                        paddingRight: "20px",
                        backgroundColor: "white"
                      }}
                      variant="outlined"
                      IconComponent={KeyboardArrowDownIcon}
                      data-test-id="electricCarMake"
                    >
                      <option value={""} />
                      {this.state.carBrand.map((item: any) => {
                        return (
                          <option
                            style={{
                              border: "1px solid white",
                              borderRadius: "5px",
                              height: "20px",
                              paddingLeft: "25px",
                              cursor:"pointer"
                            }}
                            value={item}
                            onClick={() => {
                              this.setState({
                                electricCarMake: item,
                                carModel: this.getCarModel(item)
                              });
                            }}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  {this.state.carmakeError && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Car make is mandatory
                    </p>
                  )}
                </div>
              </div>
              <div className="flexRow">
                <div
                  style={{ flexDirection: "column", width: "100%", gap: "2px" }}
                >
                  <InputLabel
                    style={{
                      color: "black",
                      marginTop: "10px",
                      marginLeft: "20px"
                    }}
                  >
                    Electric car Model*
                  </InputLabel>
                  <div className="wrapper">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      style={{
                        borderRadius: 20,
                        width: "100%",
                        paddingRight: "20px",
                        backgroundColor: "white"
                      }}
                      variant="outlined"
                      IconComponent={KeyboardArrowDownIcon}
                      data-test-id="electricCarModal"
                    >
                      <option value={""} />
                      {this.state.electricCarMake.length > 0 && this.state.carModel.map((item: any) => {
                        return (
                          <option
                            style={{
                              border: "1px solid white",
                              borderRadius: "5px",
                              height: "20px",
                              paddingLeft: "25px",
                              cursor:"pointer"
                            }}
                            value={item}
                            onClick={() => {
                              this.setState({
                                electricCarModel: item,
                                carYear: this.getCarYear(
                                  this.state.electricCarMake,
                                  item
                                )
                              });
                            }}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  {this.state.carmodelError && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Car model is mandatory
                    </p>
                  )}
                </div>
                <div
                  style={{ flexDirection: "column", width: "100%", gap: "2px" }}
                >
                  <InputLabel
                    style={{
                      color: "black",
                      marginTop: "10px",
                      marginLeft: "20px"
                    }}
                  >
                    Electric car year*
                  </InputLabel>
                  <div className="wrapper">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      style={{
                        borderRadius: 20,
                        width: "100%",
                        paddingRight: "20px",
                        backgroundColor: "white"
                      }}
                      variant="outlined"
                      IconComponent={KeyboardArrowDownIcon}
                      data-test-id="electricCarYear"
                    >
                      <option value={""} />
                      {this.state.electricCarModel.length > 0 && this.state.carYear.map((item: any) => {
                        return (
                          <option
                            style={{
                              border: "1px solid white",
                              borderRadius: "5px",
                              height: "20px",
                              paddingLeft: "25px",
                              cursor:"pointer"
                            }}
                            value={item}
                            onClick={() => {
                              this.setState({
                                electricCarYear: item,
                                batterySize: this.getCarBatterySize(
                                  this.state.electricCarMake,
                                  this.state.electricCarModel,
                                  item
                                )
                              });
                            }}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  {this.state.caryearError && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Car year is mandatory
                    </p>
                  )}
                </div>
              </div>
              <div className="flexRow">
                <div
                  style={{ flexDirection: "column", width: "100%", gap: "2px" }}
                >
                  <InputLabel
                    style={{
                      color: "black",
                      marginTop: "0px",
                      marginLeft: "20px",
                      marginBottom: "-10px"
                    }}
                  >
                    Battery Size *
                    <LightTooltip
                      title={
                        "State the cars battery size in kilowatt-hours (kWh).  If your car is software locked, to less than the full battery, enter the kWh accessible to you. Some Model Ss & Xs are locked to around 79 kW."
                      }
                      placement="bottom-end"
                    >
                      <IconButton>
                        <img src={iIcon} alt="" className="errorIcon" />
                      </IconButton>
                    </LightTooltip>
                  </InputLabel>

                  <div className="wrapper">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      style={{
                        borderRadius: 20,
                        width: "100%",
                        paddingRight: "20px",
                        backgroundColor: "white"
                      }}
                      variant="outlined"
                      IconComponent={KeyboardArrowDownIcon}
                      data-test-id="electricCarBattery"
                    >
                      <option value={""} />
                      {this.state.electricCarYear.length > 0 && this.state.batterySize.map((item: any) => {
                        return (
                          <option
                            style={{
                              border: "1px solid white",
                              borderRadius: "5px",
                              height: "20px",
                              paddingLeft: "25px",
                              cursor:"pointer"
                            }}
                            value={item}
                            onClick={() => {
                              this.setState({
                                carBatterySize: item,
                                carYearSelectError: false
                              });
                            }}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  {this.state.batterySizeError && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      Car kwh value is required.
                    </p>
                  )}
                  {this.state.carYearSelectError && this.state.electricCarYear && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      Please select the car year first.
                    </p>
                  )}
                </div>

                <CoustomTextInput
                  label="Maximum Kilowatts (kW)*"
                  value={this.state.electricCarkWh}
                  updateText={this.updateKW}
                  validater={this.kwValidater}
                  errorMssage={
                    "This is the maximum kW your charger provides and that your car can take. You can calculate kW by multiplying amps times volts and dividing by 1,000"
                  }
                  number
                  showIcon
                  showError={this.state.kwError}
                  alertMsg={this.state.kwErrorMsg}
                  style={{ paddingTop: "10px" }}
                />
              </div>
              <div className="choiceGroupContainer">
                <p>My use of the overnight charger is:</p>
                <div className="choicGroup">
                  <input
                    type="radio"
                    name="overnightChoice"
                    id="inviteAGuest"
                    value="Answer1"
                    style={{cursor:"pointer"}}
                    onChange={this.handleRadioBtn}
                  />
                  <p>
                    Exclusive to the car, identified above, unless I invite a
                    guest to stay over
                  </p>
                </div>
                <div className="choicGroup">
                  <input
                    type="radio"
                    name="overnightChoice"
                    id="livesWithMe"
                    value="Answer2"
                    style={{cursor:"pointer"}}
                    onChange={this.handleRadioBtn}
                  />
                  <p>
                    Shared with the driver of another electric car who lives
                    with me
                  </p>
                </div>
                <div className="choicGroup">
                  <input
                    type="radio"
                    name="overnightChoice"
                    id="parkingArea"
                    value="Answer3"
                    style={{cursor:"pointer"}}
                    onChange={this.handleRadioBtn}
                  />
                  <p>
                    Shared with other tenants in an apartment or at another
                    common parking area
                  </p>
                </div>
              
                {this.state.questionErrorMsg && (
                  <p
                    data-test-id="questionErrorMsg"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    Select one answer is mandatory
                  </p>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  style={{
                    width: "30%",
                    borderRadius: "30px",
                    color: "white",
                    fontWeight: "bolder",
                    marginTop: "10px",
                    marginBottom: "10px",
                    textTransform: "none",
                    padding: "2% 0%",
                    fontSize: "18px"
                  }}
                  variant="contained"
                  color="primary"
                  onClick={this.createAccount}
                  className="createnewaccountBtn"
                  data-test-id="signupBtn"
                >
                  Signup
                </Button>
              </div>
              <div className="termAndConditionContainer">
                <input
                  type="checkbox"
                  checked={this.state.termandcondition}
                  onChange={this.handleTermandCondition}
                  className="termsAndConditionCheckbox"
                  data-test-id="TermAndCondition"
                  style={{cursor:"pointer"}}
                />
                <p style={{ fontSize: "12px" }}>
                  I have read and agree to{" "}
                  <p style={{ marginLeft: "7px" }}>
                    <span
                      data-test-id="checkboxId"
                      onClick={() => {
                        this.setState({ modalState: !this.state.modalState });
                      }}
                    >
                      Terms and Conditions
                    </span>
                  </p>
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
        <SimpleBottomNavigation />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
